import React, { useCallback, useContext, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import FormError from '../../styled/form-error'
import { axios, context as AuthContext } from '../../context/auth'

import InputGroup from '../../styled/input-group'
import AuthFormContainer from '../../styled/auth-form-container'
import { PrimaryButton } from '../../styled/button'

import { pageView, PAGES } from '../../utils/analytics-tracker'

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string().min(6).max(128).required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

const NewPassword = () => {
  const navigate = useNavigate()
  const { token } = useParams()

  const { refreshUser } = useContext(AuthContext)

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setErrors } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: NewPasswordSchema,
    onSubmit: (formValues) => submit(formValues)
  })

  useEffect(() => {
    pageView(PAGES.PASSWORD_NEW)
  }, ['-'])

  const submit = useCallback(async (values) => {
    try {
      await axios.post('/auth/password-reset', {
        token,
        password: values.password,
        confirmPassword: values.confirmPassword
      })
      refreshUser()
      navigate('/songs')
    } catch (err) {
      if (err.response.status === 400) {
        setErrors(err.response.data)
      } else {
        console.log(err)
      }
    }
  })

  return (
    <>
      <h1>Create New Password</h1>
      <AuthFormContainer>
        <InputGroup>
          <label htmlFor='password'>Password: </label>
          <input
            type='password'
            name='password'
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.password && errors.password && <FormError>{errors.password}</FormError>}
          <label htmlFor='confirmPassword'>Confirm Password: </label>
          <input
            type='password'
            name='confirmPassword'
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.confirmPassword && errors.confirmPassword && <FormError>{errors.confirmPassword}</FormError>}
          <PrimaryButton type='submit' onClick={handleSubmit}>Set Password</PrimaryButton>
        </InputGroup>
      </AuthFormContainer>
    </>
  )
}

export default NewPassword
