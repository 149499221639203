import React from 'react'
import { Link } from 'react-router-dom'
import SongItem from '../../styled/song-item'

import getSongFirstChord from '../../utils/song-first-chord'
import timeDifference from '../../utils/time-difference'

const getAddedField = (song, addFieldKey) => {
  switch (addFieldKey) {
    case '':
    case undefined:
      return null
    // case 'duration':
    //   return `( ${song.duration || '?'} )`
    case 'first':
      return `( ${getSongFirstChord(song)} )`
    case 'lastRehearsed':
      return `( ${timeDifference(song.lastRehearsed)} )`
    default:
      return `( ${song[addFieldKey] || '?'} )`
  }
}

export default ({ song, setlistid, sortBy, addFieldKey }) => {
  return (
    <>
      <SongItem>
        <Link to={(setlistid) ? `/setlists/${setlistid}/song/${song._id}/` : `/songs/${song._id}`}>
          <div>{song.title} {getAddedField(song, addFieldKey)}</div>
          <div>
            <em>{song.artist}</em>
            {sortBy === 'lastRehearsed' && <> / {timeDifference(song.lastRehearsed)}</>}
          </div>
        </Link>
      </SongItem>
    </>
  )
}
