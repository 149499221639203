import React from 'react'
import { confirmable } from 'react-confirm'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import getTheme from '../../styled/theme'

import { ConfirmModal, ConfirmContent, ConfirmMessage, ConfirmButtons } from '../../styled/confirm-modal'
import { PrimaryButton } from '../../styled/button'

const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

const preventThen = (e, fn) => {
  e.preventDefault()
  fn()
}

const DialogBox = ({ show, proceed, cancel, confirmation, confirmLabel = 'Yes', cancelLabel = 'Cancel', ok = false }) => (
  <ConfirmModal show={show}>
    <ThemeProvider theme={getTheme(isDarkMode ? 'dark' : '')}>
      <ConfirmContent>
        <ConfirmMessage>{confirmation}</ConfirmMessage>
        <ConfirmButtons>
          {ok && <PrimaryButton onClick={(e) => preventThen(e, proceed)}>Close</PrimaryButton>}
          {!ok && (
            <>
              <PrimaryButton onClick={(e) => preventThen(e, proceed)}>{confirmLabel}</PrimaryButton>
              <PrimaryButton onClick={(e) => preventThen(e, cancel)}>{cancelLabel}</PrimaryButton>
            </>
          )}
        </ConfirmButtons>
      </ConfirmContent>
    </ThemeProvider>
  </ConfirmModal>
)

DialogBox.propTypes = {
  show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
  confirmation: PropTypes.object // arguments of your confirm function
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(DialogBox)
