import React, { useState, useCallback, useContext, useEffect } from 'react'
import _get from 'lodash.get'
import _map from 'lodash.map'
import styled from 'styled-components'
import { FaMinusCircle } from 'react-icons/fa'

import UserPicker from './user-picker'
import confirm from '../confirmable'

import { axios, context as AuthContext } from '../../context/auth'
import { event, ACTIONS } from '../../utils/analytics-tracker'
import { isOwner } from '../../utils/auth'

import OptionsContainer, { Title } from '../../styled/options-container'
import { ConfirmFocal } from '../../styled/confirm-modal'
import LinkButton from '../../styled/link-button'

/**
 * Displays users and groups with permissions to the given object
 * (only ones the user is allowed to see)
 */

const PermRow = styled.div`
  width: 100%;
  display: block;
  margin-bottom: .5rem;
`
const PermName = styled.div`
  width: 60%;
  display: inline-block;
`
const PermRole = styled.div`
  width: 25%;
  display: inline-block;
`
const PermRemove = styled.div`
  width: 15%;
  display: inline-block;
`

const objectDisplay = obj => _get(obj, '_doc.title') || obj.email

const confirmMessage = (removeObj, fromObj) => {
  return (
    <div>Are you sure you want to remove
      <ConfirmFocal>{objectDisplay(removeObj)}</ConfirmFocal>
      from
      <ConfirmFocal>{objectDisplay(fromObj)}</ConfirmFocal>
      ?
    </div>
  )
}

const canRemove = (objUser, authUser) => {
  return objUser._id !== authUser._id
}

const ObjectUsers = ({ category, objectId, apiPath, obj = {}, roles }) => {
  const [permObject, setPermObject] = useState(obj)
  const [owner, setOwner] = useState()
  const [updateTime, setUpdateTime] = useState(Date.now())

  const refresh = useCallback(async () => {
    try {
      const updated = await axios({
        url: `/api/${apiPath}/${objectId}`,
        method: 'GET'
      })
      setPermObject(updated.data || {})
      setOwner(isOwner(updated.data))
    } catch (e) {
      console.log(e)
    }
  })

  useEffect(() => {
    refresh()
  }, [updateTime])

  const addUser = useCallback(async (userId, role) => {
    try {
      await axios({
        url: `/api/${apiPath}/${permObject._id}/users`,
        method: 'POST',
        data: {
          userid: userId,
          role
        }
      })
      event(category, ACTIONS.USER_ADDED)
      setUpdateTime(Date.now())
    } catch (e) {
      console.log(e)
    }
  }, [permObject])

  const addGroup = useCallback(async (groupId, role) => {
    try {
      await axios({
        url: `/api/${apiPath}/${permObject._id}/groups`,
        method: 'POST',
        data: {
          groupid: groupId,
          role
        }
      })
      event(category, ACTIONS.GROUP_ADDED)
      setUpdateTime(Date.now())
    } catch (e) {
      console.log(e)
    }
  }, [permObject])

  const removeUser = useCallback((user, e) => {
    e.preventDefault()
    confirm(confirmMessage(user, permObject)).then(
      async (result) => {
        try {
          await axios({
            url: `/api/${apiPath}/${permObject._id}/users/${user._id}`,
            method: 'DELETE'
          })
          event(category, ACTIONS.USER_REMOVED)
          setUpdateTime(Date.now())
        } catch (e) {
          console.log(e)
        }
      },
      (result) => {
        event(category, ACTIONS.GROUP_REMOVE_CANCEL)
      }
    )
  }, [permObject])

  const removeGroup = useCallback((group, e) => {
    e.preventDefault()
    confirm(confirmMessage(group, permObject)).then(
      async (result) => {
        try {
          await axios({
            url: `/api/${apiPath}/${permObject._id}/groups/${group._doc._id}`,
            method: 'DELETE'
          })
          event(category, ACTIONS.GROUP_REMOVED)
          setUpdateTime(Date.now())
        } catch (e) {
          console.log(e)
        }
      },
      (result) => {
        event(category, ACTIONS.USER_REMOVE_CANCEL)
      }
    )
  }, [permObject])

  const { users, groups } = permObject
  const { user } = useContext(AuthContext)

  return (
    <OptionsContainer>
      <div className='ObjectUsersList'>
        <Title>Permissions</Title>
        {_map(users, objUser => {
          return (
            <PermRow key={objUser._id}>
              <PermName>{objUser.email}</PermName>
              <PermRole>{objUser.role}</PermRole>
              <PermRemove>{owner && canRemove(objUser, user) && <LinkButton icon='FaMinusCircle' onClick={(e) => removeUser(objUser, e)}><FaMinusCircle /></LinkButton>}</PermRemove>
            </PermRow>
          )
        })}
        {apiPath !== 'groups' && _map(groups, objGroup => {
          return (
            <PermRow key={objGroup._doc._id}>
              <PermName>{objGroup._doc.title}</PermName>
              <PermRole>{objGroup.role}</PermRole>
              <PermRemove>{owner && canRemove(objGroup, user) && <LinkButton icon='FaMinusCircle' onClick={(e) => removeGroup(objGroup, e)}><FaMinusCircle /></LinkButton>}</PermRemove>
            </PermRow>
          )
        })}
      </div>
      {owner && <UserPicker addUser={addUser} addGroup={addGroup} roles={roles} isGroup={!!(apiPath === 'groups')} />}
    </OptionsContainer>
  )
}

export default ObjectUsers
