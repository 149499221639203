import React from 'react'
import styled from 'styled-components'

const LinkButton = styled.button`
  display: inline-flex;
  align-items: center; 
  background: none!important;
  border: none;
  padding: 0!important;
  text-decoration: none;
  cursor: pointer;
  margin-left: 5px;
  font-weight: 100;
  color: ${({ theme }) => theme.secondary};
  :hover {
    text-decoration: underline;
  }
`

export default ({ onClick, children, title, className }) => <LinkButton className={className} title={title} onClick={onClick}>{children}</LinkButton>
