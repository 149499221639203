import React, { createContext, useCallback, useEffect, useState } from 'react'
import a from 'axios'
import { v4 as uuid } from 'uuid'
import PropTypes from 'prop-types'
import windowStorage from '../utils/window-storage'

import { DEVICE_ID_KEY } from '../constants'

const AuthContext = createContext()

const getCreateDeviceId = () => {
  const current = windowStorage.getItem(DEVICE_ID_KEY)
  if (current !== null && current !== undefined) {
    return current
  }

  const newDeviceId = uuid()
  windowStorage.setItem(DEVICE_ID_KEY, newDeviceId)
  return newDeviceId
}

export const axios = a.create({
  headers: {
    deviceId: getCreateDeviceId()
  }
})

const AuthProviderComponent = ({ children, history }) => {
  const [user, setUser] = useState()
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  // const [redirectTo, setRedirectTo] = useState()

  const _refreshUser = async () => {
    try {
      const response = await axios.get('/auth/user', { skipAuthRefresh: true })
      if (response) {
        setUser(response.data)
        setLoggedIn(true)
      }
    } catch (err) {
      // history.push('/login')
      console.log(err)
    }
  }

  useEffect(() => {
    const startup = async () => {
      // Function that will be called to refresh authorization
      const refreshAuthLogic = (originalRequest) => axios
        .post('/auth/refresh',
          { refreshToken: window.localStorage.getItem('refreshToken') },
          { isRefresh: true }
        )
        .then(tokenRefreshResponse => {
          window.localStorage.setItem('refreshToken', tokenRefreshResponse.headers['x-refresh-token'])
          window.sessionStorage.setItem('accessToken', tokenRefreshResponse.headers['x-access-token'])
          return axios(originalRequest)
        })
        .catch(err => {
          console.log('caught err on refresh: ', err)
          setUser(undefined)
          return Promise.resolve()
        })

      // Detect 401 response and retry if not already retried
      axios.interceptors.response.use((response) => {
        return response
      }, error => {
        const originalRequest = error.config
        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry && !originalRequest.isRefresh) {
          originalRequest._retry = true
          return refreshAuthLogic(originalRequest)
        }
        return error
      })

      // interceptor that appends access token header to all requests
      axios.interceptors.request.use(config => {
        const accessToken = window.sessionStorage.getItem('accessToken')
        if (accessToken) {
          config.headers['x-access-token'] = accessToken
        }
        return config
      }, error => {
        return Promise.reject(error)
      })
      await _refreshUser()
      setLoading(false)
    }
    startup()
  }, [])

  const _logout = useCallback(async (event) => {
    event.preventDefault()
    return axios.get('/auth/logout').then(response => {
      if (response.status === 200) {
        window.sessionStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
        setUser(null)
        setLoggedIn(false)
        window.location.href = '/'
        return true
      }
      return false
    })
  })

  const _login = useCallback(async (email, password) => {
    return axios
      .post('/auth/login', {
        email,
        password
      }, { skipAuthRefresh: true })
      .then(response => {
        if (response.status === 200) {
          window.sessionStorage.setItem('accessToken', response.headers['x-access-token'])
          window.localStorage.setItem('refreshToken', response.headers['x-refresh-token'])
          setUser(response.data)
          return true
        } else {
          return false
        }
      })
      .catch((err) => {
        console.log('err on login', err)
        return false
      })
  })

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        user,
        login: _login,
        logout: _logout,
        loading,
        refreshUser: _refreshUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProviderComponent.propTypes = {
  children: PropTypes.element
}

export const context = AuthContext

export const AuthProvider = AuthProviderComponent
export const AuthConsumer = AuthContext.Consumer
