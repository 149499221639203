import React, { useEffect, useState } from 'react'
import { axios } from '../../context/auth'
import { Link } from 'react-router-dom'
import SongItem from '../../styled/song-item'

import SongListContainer from '../../styled/song-list'
import { pageView, PAGES } from '../../utils/analytics-tracker'
// import ShowsList from '../shows/list'

const SetListList = () => {
  const [setlists, setSetlists] = useState([])

  useEffect(() => {
    pageView(PAGES.SETLIST_LIST_VIEW)
    const load = async () => {
      try {
        const response = await axios.get('/api/setlists')
        setSetlists(response.data)
      } catch (err) {
        console.log(err)
      }
    }
    load()
  }, ['-'])

  return (
    <>
      {/* <ShowsList /> */}
      <SongListContainer>
        <div className='setListListHeader'>
          <h2>My Set Lists</h2>
        </div>
        {setlists.map(setlist =>
          <SongItem key={setlist._id}>
            <Link key={setlist._id} to={`/setlists/${setlist._id}`}>
              <div>{setlist.title}</div>
              <div>{setlist.songs.length} songs</div>
            </Link>
          </SongItem>)}
        <Link to='/new-setlist'>New Set List</Link>
      </SongListContainer>
    </>
  )
}

export default SetListList
