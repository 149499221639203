import React, { useEffect, useCallback, useContext, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { axios, context as AuthContext } from '../../context/auth'

import FormError from '../../styled/form-error'
import InputGroup from '../../styled/input-group'
import AuthFormContainer from '../../styled/auth-form-container'
import { PrimaryButton } from '../../styled/button'

const ConfirmRegistrationSchema = Yup.object().shape({
  email: Yup.string().min(6).max(150).email().required()
})

const ConfirmRegistration = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const [confirmed, setConfirmed] = useState()
  const [savedEmail, setSavedEmail] = useState()

  const { refreshUser } = useContext(AuthContext)

  useEffect(() => {
    if (savedEmail) {
      confirmRegistration()
    }
  }, [savedEmail])

  const { values, errors, touched, setErrors, setFieldValue, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ConfirmRegistrationSchema,
    onSubmit: (formValues) => confirmRegistration(formValues)
  })

  useEffect(() => {
    const savedEmail = window.localStorage.getItem('tabifyRegisterEmail')
    window.localStorage.removeItem('tabifyRegisterEmail')

    if (savedEmail) {
      setFieldValue('email', savedEmail)
      setSavedEmail()
    }
  }, ['-'])

  const confirmRegistration = useCallback(async (values) => {
    try {
      await axios.post('/auth/confirm-registration', {
        email: values.email,
        token
      })

      setConfirmed('yes')
      refreshUser()
      setTimeout(() => {
        navigate('/songs')
      }, 2000)
    } catch (err) {
      if (err.response.status === 400) {
        setErrors(err.response.data)
      } else {
        console.log(err)
      }
    }
  }, [token])

  if (confirmed) {
    return <div>All set!  Welcome to tabify.  You'll be redirected to the dashboard in a second.</div>
  }

  return (
    <AuthFormContainer>
      <InputGroup>
        Almost done...
        <label htmlFor='email'>Confirm Email Address:</label>
        <input type='text' name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
        {touched.email && errors.email && <FormError>{errors.email}</FormError>}
        <PrimaryButton type='submit' name='Complete Registration' onClick={handleSubmit}>Complete Registration</PrimaryButton>
      </InputGroup>
    </AuthFormContainer>
  )
}

export default ConfirmRegistration
