import React, { useCallback, useEffect, useState } from 'react'
import { axios } from '../../context/auth'
import _includes from 'lodash.includes'
import _map from 'lodash.map'
import _remove from 'lodash.remove'

import CommonTags from './common'

/**
 * Custom component for getting and setting tags for song search
 * wraps common tags component
 *
 * @param {} param0
 * @returns
 */
let tagsSource
const SearchTags = ({ canEdit, changeFn, noCreate = true, filter }) => {
  const [tags, setTags] = useState([])
  const [suggestions, setSuggestions] = useState([])

  const refreshTags = useCallback(async () => {
    try {
      // tagsSource = axios.CancelToken.source() { cancelToken: tagsSource.token }
      const response = await axios.get('/api/tags')
      setSuggestions(_map(response.data, userTag => userTag.label))
    } catch (err) {
      console.log(err)
    }
  })

  const handleInput = useCallback((value) => {
    changeFn('filter', value)
  })

  const handleAddition = useCallback(async (tag) => {
    const newTag = tag.replace(/[^a-z-_0-9+]+/gi, '').toLowerCase()

    if (!_includes(suggestions, newTag)) {
      // we need to create this tag first
      await axios.post('/api/tags', { label: newTag })
    }

    const newTags = _includes(tags, tag) ? tags : [...tags, newTag]

    // this needs to be sent to the search filter
    setTags(newTags)
    setSuggestions(_includes(suggestions, tag) ? suggestions : [...suggestions, newTag])

    changeFn('filter', '')
    changeFn('tags', newTags.join(','))
  })

  const handleDelete = useCallback(async (tag) => {
    const newTags = _remove(tags, currentTag => currentTag !== tag)
    setTags(newTags)
    changeFn('tags', newTags.join(','))
  })

  useEffect(() => {
    refreshTags()
    return () => {
      if (tagsSource) {
        tagsSource.cancel('tags fetch canceled, search tags unmounted')
      }
    }
  }, ['-'])

  if (!tags) {
    return 'Loading tags...'
  }
  return (
    <CommonTags
      label='Search/Tags'
      tags={tags}
      suggestions={suggestions}
      canEdit
      filter={filter}
      handleAddition={handleAddition}
      handleDelete={handleDelete}
      handleInput={handleInput}
      noCreate={noCreate}
      placeholder='Search'
    />
  )
}

export default SearchTags
