export const SONG_KEYS = [
  '',
  'C',
  'Cm',
  'Db/C#',
  'C#m',
  'D',
  'Dm',
  'Eb',
  'Ebm',
  'E',
  'Em',
  'F',
  'Fm',
  'Gb/F#',
  'Gbm/F#m',
  'G',
  'Gm',
  'Ab',
  'Abm',
  'A',
  'Am',
  'Bb',
  'Bbm',
  'B',
  'Bm'
]

export const SIGNATURES = [
  { display: '', value: 0 },
  { display: '4/4', value: 4 },
  { display: '3/4', value: 3 },
  { display: '6/8', value: 6 },
  { display: '4/5', value: 5 }
]

export const DEVICE_ID_KEY = 'tabify.device.id'
