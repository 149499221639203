import React, { useCallback, useState } from 'react'

import _map from 'lodash.map'

const SetListPicker = ({ setLists, onAddSetlist }) => {
  const [selected, setSelected] = useState('new')

  const changeSelected = useCallback((e) => {
    setSelected(e.target.value)
  })

  const addToShow = useCallback(() => {
    onAddSetlist(selected)
  })

  return (
    <>
      <select onChange={changeSelected}>
        <option value='new'>Create New</option>
        {_map(setLists, sl => <option value={sl._id}>{sl.title}</option>)}
      </select>
      <button onClick={addToShow}>Add to Show</button>
    </>
  )
}

export default SetListPicker
