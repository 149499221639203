import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { axios } from '../../context/auth'

import InputGroup from '../../styled/input-group'
import { PrimaryButton } from '../../styled/button'
import FormError from '../../styled/form-error'

import { pageView, PAGES } from '../../utils/analytics-tracker'

const EditGroup = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { groupid } = useParams()

  const isEdit = location.pathname.endsWith('edit')
  const [group, setGroup] = useState({
    title: ''
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchGroup = async () => {
      pageView(PAGES.GROUP_EDIT)
      if (groupid) {
        try {
          const response = await axios.get(`/api/groups/${groupid}`)
          setGroup(response.data)
        } catch (e) {
          console.log(e)
        }
      }
    }
    fetchGroup()
  }, [groupid])

  const handleTitleChange = useCallback((event) => {
    setGroup({
      ...group,
      title: event.target.value
    })
  })

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    // TODO - validate!
    const url = isEdit ? `/api/groups/${group._id}` : '/api/groups'
    const method = isEdit ? 'PUT' : 'POST'
    try {
      const response = await axios({
        url,
        method,
        data: {
          title: group.title
        }
      })
      navigate(`/groups/${response.data._id}`)
    } catch (err) {
      if (err.response.status === 400) {
        setErrors(err.response.data)
      } else {
        console.log('err', err.response)
      }
    }
  })

  return (
    <div className='groupForm'>
      <InputGroup>
        <label htmlFor='title'>Group name</label>
        <input
          type='text'
          name='title'
          value={group.title}
          onChange={handleTitleChange}
        />
        {errors.title && <FormError>{errors.title}</FormError>}
      </InputGroup>
      <PrimaryButton onClick={handleSubmit}>Save Group</PrimaryButton>
    </div>
  )
}

export default EditGroup
