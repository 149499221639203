import { axios } from '../context/auth'
import _each from 'lodash.foreach'
import _pick from 'lodash.pick'
import _map from 'lodash.map'

import windowStorage from './window-storage'

const minResponseTime = 500

const minimizeSetlistContent = (setlist) => {
  return {
    ...setlist,
    songs: _map(setlist.songs, song => _pick(song, ['_id']))
  }
}

const minimizeSongContent = (song) => {
  return _pick(song, ['_id', 'artist', 'title', 'content', 'notes'])
}

export default (config) => {
  if (!config.key || !config.id) {
    throw Error('key and id are required (song/setlst/group) in config of caching client')
  }

  return new Promise((resolve, reject) => {
    const start = Date.now()
    axios(config).then(response => {
      switch (config.key) {
        case 'setlist':
          windowStorage.setItem(`${config.key}-${config.id}`, JSON.stringify(minimizeSetlistContent(response.data)))
          _each(response.data.songs, song => {
            windowStorage.setItem(`song-${song._id}`, JSON.stringify(minimizeSongContent(song)))
          })
          break
        case 'song':
          windowStorage.setItem(`${config.key}-${config.id}`, JSON.stringify(minimizeSongContent(response.data)))
          break
        case 'song-list':
          break
      }

      const end = Date.now()
      if (end - start > minResponseTime) {
        resolve(response)
      } else {
        setTimeout(() => {
          resolve(response)
        }, minResponseTime - (end - start))
      }
    }).catch(err => {
      const cached = windowStorage.getItem(`${config.key}-${config.id}`)
      if (cached) {
        resolve({
          data: {
            ...JSON.parse(cached),
            cached: true
          }
        })
      } else {
        reject(err)
      }
    })

    setTimeout(() => {
      const cached = windowStorage.getItem(`${config.key}-${config.id}`)
      if (cached) {
        resolve({
          data: {
            ...JSON.parse(cached),
            cached: true
          }
        })
      }
    }, 5000)
  })
}
