import styled from 'styled-components'

export default styled.div`
  margin-bottom: ${({ theme }) => theme.margin};

  .control-box-header {
    background: ${({ theme }) => theme.borderColor};
    color: ${({ theme }) => theme.bodyText};
    padding: 0.8rem;
    cursor: pointer;

    &:before {
      content: ${({ closed }) => closed ? "'+'" : "'-'"};
      display: inline-block;
      width: 15px;
    }

    &:hover {
      background: ${({ theme }) => theme.borderColorDark};
    }
  }

  .control-box-body {
    display: ${({ closed }) => closed ? 'none' : 'flex'};
    padding: ${({ theme }) => `${theme.margin} ${theme.margin} 0`};
    border: ${({ theme }) => `1px solid ${theme.borderColor}`};
    align-items: center;

    > div {
      margin-left: ${({ theme }) => theme.margin};

      &:first-child {
        margin-left: 0;
      }
    }
  }
`
