import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { axios } from '../../context/auth'
import _each from 'lodash.foreach'
import _map from 'lodash.map'

import ViewShowSetList from '../setlists/show-view'
import SetListPicker from './setlist-picker'

import Loader from '../../styled/loader'

const ShowEdit = ({ show, availableSetlists }) => {
  const [workingShow, setWorkingShow] = useState(show)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [setLists, setSetLists] = useState([])

  const addSetlist = useCallback(async (addSetList) => {
    setHasUnsavedChanges(true)
    if (addSetList === 'new') {
      // create setlist first
      const setListName = `${show.name} - ${setLists.length + 1}`

      try {
        const newSetListResponse = await axios({
          url: '/api/setlists',
          method: 'POST',
          data: {
            title: setListName,
            songs: []
          }
        })
        addSetList = newSetListResponse.data._id
        setSetLists([
          ...setLists,
          newSetListResponse.data
        ])
        setWorkingShow({
          ...workingShow,
          setlists: [
            ...workingShow.setlists,
            addSetList
          ]
        })
      } catch (e) {
        console.log(e)
      }
    } else {
      setSetLists([
        ...setLists,
        addSetList
      ])
    }
  }, [setLists, show, workingShow])

  const showAddSetlist = setLists.length < 5

  return (
    <>
      {hasUnsavedChanges && <div>hasUnsavedChanges!!!!!!!!!!!!!!!!!!!!!!!</div>}
      <h3>{show.title}</h3>
      {showAddSetlist && <SetListPicker setLists={availableSetlists} onAddSetlist={addSetlist} />}
      {_map(setLists, (setList, idx) => <ViewShowSetList setList={setList} idx={idx} />)}
    </>
  )
}

// the wrapper gets the show then populates the setlists in the show
const EditShowWrapper = () => {
  const { showid } = useParams()
  const [show, setShow] = useState()
  const [showSetlists, setShowSetlists] = useState()
  const [setlists, setSetlists] = useState()

  useEffect(() => {
    const load = async () => {
      try {
        const response = await axios.get(`/api/shows/${showid}`)
        setShow(response.data)
      } catch (err) {
        console.log(err)
      }
      try {
        const setListsResponse = await axios.get('/api/setlists')
        setSetlists(setListsResponse.data)
      } catch (err) {
        console.log(err)
      }
    }
    load()
  }, [showid])

  useEffect(() => {
    if (!show) {
      return
    }

    _each(show.setlists, async (setlist, idx) => {
      try {
        const response = await axios.get(`/api/setlists/${setlist}`)
        const newSetLists = [
          ...showSetlists
        ]
        newSetLists[idx] = response.data
        setShowSetlists(newSetLists)
      } catch (err) {

      }
    })
  }, [show])

  if (!show || !setlists) {
    return <Loader />
  }
  return <ShowEdit show={show} availableSetlists={setlists} showSetlists={showSetlists} />
}

export default EditShowWrapper
