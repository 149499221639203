import React, { useCallback, useEffect, useState } from 'react'
import { axios } from '../../context/auth'
// import _each from 'lodash.foreach'
import _map from 'lodash.map'

import { Link, useParams } from 'react-router-dom'

import { canEdit as canEditFn, isOwner as isOwnerFn } from '../../utils/auth'

import ViewShowSetList from '../setlists/show-view'

import Loader from '../../styled/loader'

const ViewShow = ({ show }) => {
  const { showid } = useParams()
  const [setLists] = useState([])
  const canEdit = canEditFn(show)
  const isOwner = isOwnerFn(show)

  const deleteShow = useCallback(() => {

  })

  useEffect(() => {
    const load = async () => {
      // _each(show.setlists, async (setlist, idx) => {
      //   try {
      //     const response = await axios.get(`/api/setlists/${setlist}`)
      //     const newSetLists = [
      //       ...setLists
      //     ]
      //     newSetLists[idx] = response.data
      //     setSetLists(newSetLists)
      //   } catch (err) {

      //   }
      // })
    }
    load()
  }, [show])

  return (
    <>
      <h3>{show.title}
        {canEdit && <Link to={`/shows/${showid}/edit`}>✎</Link>}
        {isOwner && <Link to={`/songs/${showid}`} onClick={deleteShow}>&#9249;</Link>}
      </h3>
      {_map(setLists, setList => <ViewShowSetList setList={setList} />)}
    </>
  )
}

const ViewShowWrapper = () => {
  const { showid } = useParams()
  const [show, setShow] = useState()

  useEffect(() => {
    const load = async () => {
      try {
        const response = await axios.get(`/api/shows/${showid}`)
        setShow(response.data)
      } catch (err) {
        console.log(err)
      }
    }
    load()
  }, [showid])

  if (!show) {
    return <Loader />
  }
  return <ViewShow show={show} />
}

export default ViewShowWrapper
