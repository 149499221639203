import React from 'react'
import { axios } from '../../context/auth'
import styled from 'styled-components'

import OptionsContainer, { MarginButton, Title } from '../../styled/options-container'

import timeDifference from '../../utils/time-difference'

const update = async (songid, refreshSongFn) => {
  const updated = await axios.patch(`/api/songs/${songid}/rehearsed`)
  refreshSongFn(updated)
}

const FlexHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export default ({ songid, lastRehearsed, refreshSong }) => {
  return (
    <OptionsContainer>
      <FlexHeader>
        <Title>Last Rehearsal</Title>
        <div><b>{timeDifference(lastRehearsed)}</b></div>
        <MarginButton onClick={() => update(songid, refreshSong)}>Update</MarginButton>
      </FlexHeader>
    </OptionsContainer>
  )
}
