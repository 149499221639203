import React from 'react'
import styled from 'styled-components'

import { FaCaretUp, FaCaretDown } from 'react-icons/fa'
import { marginBottom } from '../../../variables'
import { SmallButton } from '../../../styled/button'
import { canEdit } from '../../../utils/auth'

const Arrow = styled.span`
  color: ${({ active, theme }) => active ? theme.secondary : theme.primary};
  cursor: ${props => props.enabled ? 'pointer' : 'default'};
  opacity: ${props => props.enabled ? '1' : '.3'};
`

const TransposeContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: ${marginBottom};
`

const SmallButtonMargin = styled(SmallButton)`
  margin-left: 10px;
`

export default ({ song, value, upFn, downFn, saveTransposedFn, saving }) => {
  const upEnabled = true
  const downEnabled = true
  const changed = song.transposed !== value
  return (
    <>
      <TransposeContainer>
        <Arrow enabled={upEnabled}>
          <FaCaretUp onClick={upEnabled ? upFn : n => n} size={32} />
        </Arrow>
        <Arrow enabled={downEnabled}>
          <FaCaretDown onClick={downEnabled ? downFn : n => n} size={32} />
        </Arrow>
        {(value !== 0 || changed) && <Arrow marginRight='5px' enabled>{value > 0 ? '+' : ''}{value}</Arrow>}
        {canEdit(song) && changed && <SmallButtonMargin disabled={saving} onClick={saveTransposedFn}>{saving ? 'Saving...' : 'Save'}</SmallButtonMargin>}
      </TransposeContainer>
      <div style={{ clear: 'both' }} />
    </>
  )
}
