import React from 'react'
import styled from 'styled-components'
import { FaEdit, FaFileExport, FaTrash, FaCopy } from 'react-icons/fa'

import LinkButton from './link-button'

const PaddedLinkButton = styled(LinkButton)`
  margin-left: 5px;
`

export const Delete = ({ onClick, title }) => (
  <PaddedLinkButton onClick={onClick} title={title}>
    <FaTrash />
  </PaddedLinkButton>
)

export const Edit = ({ onClick, title }) => (
  <PaddedLinkButton onClick={onClick} title={title}>
    <FaEdit />
  </PaddedLinkButton>
)

export const Export = ({ onClick, title }) => (
  <PaddedLinkButton onClick={onClick} title={title}>
    <FaFileExport />
  </PaddedLinkButton>
)

export const Copy = ({ onClick, title }) => (
  <PaddedLinkButton onClick={onClick} title={title}>
    <FaCopy />
  </PaddedLinkButton>
)
