export default (lastRehearsed, now = Date.now()) => {
  if (!lastRehearsed) {
    return 'Never'
  }

  const date1 = new Date(lastRehearsed)
  const date2 = new Date(now)
  const diffTime = Math.abs(date2.getTime() - date1.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  if (diffTime / (1000 * 60 * 60 * 24) < 1) {
    return 'Today'
  }

  if (diffDays === 1) {
    return '1 day ago'
  }

  return `${diffDays} days ago`
}
