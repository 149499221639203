import _each from 'lodash.foreach'
import _find from 'lodash.find'
import _map from 'lodash.map'
import _uniq from 'lodash.uniq'

const songKeys = [ // since adding minor keys, major = primary chords, minor = secondary
  { key: 'C', crossHarp: 'G', major: ['C', 'F', 'G'], minor: ['D', 'E', 'A'], dim: ['B'] },
  { key: 'Cm', crossHarp: 'Gm', major: ['Ab', 'G#', 'Bb', 'Eb', 'D#'], minor: ['C', 'F', 'G'], dim: ['D'] },

  { key: 'G', crossHarp: 'D', major: ['G', 'C', 'D'], minor: ['A', 'B', 'E'], dim: ['F#', 'Gb'] },
  { key: 'Gm', crossHarp: 'Dm', major: ['Eb', 'D#', 'F', 'Bb'], minor: ['G', 'C', 'D'], dim: ['A'] },

  { key: 'D', crossHarp: 'A', major: ['D', 'G', 'A'], minor: ['E', 'F#', 'Gb', 'B'], dim: ['C#', 'Db'] },
  { key: 'Dm', crossHarp: 'Am', major: ['Bb', 'C', 'F'], minor: ['D', 'G', 'A'], dim: ['E'] },

  { key: 'A', crossHarp: 'E', major: ['A', 'D', 'E'], minor: ['B', 'C#', 'Db', 'F#', 'Gb'], dim: ['G#', 'Ab'] },
  { key: 'Am', crossHarp: 'Em', major: ['F', 'G', 'C'], minor: ['A', 'D', 'E'], dim: ['B'] },

  { key: 'E', crossHarp: 'B', major: ['E', 'A', 'B'], minor: ['F#', 'Gb', 'G#', 'Ab', 'C#', 'Db'], dim: ['D#', 'Eb'] },
  { key: 'Em', crossHarp: 'Bm', major: ['C', 'D', 'G'], minor: ['E', 'A', 'B'], dim: ['F#', 'Gb'] },

  { key: 'B', crossHarp: 'F#', major: ['B', 'E', 'F#', 'Gb'], minor: ['C#', 'Db', 'D#', 'Eb', 'G#', 'Ab'], dim: ['A#', 'Bb'] },
  { key: 'Bm', crossHarp: 'F#m', major: ['G', 'A', 'D'], minor: ['B', 'E', 'F#'], dim: ['C#', 'Db'] },

  { key: 'F', crossHarp: 'C', major: ['F', 'Bb', 'A#', 'C'], minor: ['G', 'A', 'D'], dim: ['E'] },
  { key: 'Fm', crossHarp: 'Cm', major: ['C#', 'Db', 'Eb', 'Ab'], minor: ['F', 'Bb', 'A#', 'C'], dim: ['G'] },

  { key: 'Bb', crossHarp: 'F', major: ['Bb', 'A#', 'Eb', 'D#', 'F'], minor: ['C', 'D', 'G'], dim: ['A'] },
  { key: 'Bbm', crossHarp: 'Fm', major: ['Gb', 'Ab', 'Db'], minor: ['Bb', 'A#', 'Eb', 'F'], dim: ['C'] },

  { key: 'Eb', crossHarp: 'Bb', major: ['Eb', 'D#', 'Ab', 'G#', 'Bb', 'A#'], minor: ['F', 'G', 'C'], dim: ['D'] },
  { key: 'Ebm', crossHarp: 'Bbm', major: ['B', 'C#', 'Db', 'Gb'], minor: ['D#', 'Eb', 'Ab', 'Bb'], dim: ['F'] },

  { key: 'Ab', crossHarp: 'Eb', major: ['Ab', 'G#', 'Db', 'C#', 'Eb', 'D#'], minor: ['Bb', 'A#', 'C', 'F'], dim: ['G'] },
  { key: 'Abm', crossHarp: 'Ebm', major: ['Fb', 'Gb', 'B'], minor: ['Ab', 'Db', 'Eb'], dim: ['Bb', 'A#'] },

  { key: 'C#', crossHarp: 'Ab', major: ['Db', 'C#', 'Gb', 'F#', 'Ab', 'G#'], minor: ['Eb', 'D#', 'F', 'Bb', 'A#'], dim: ['C'] },
  { key: 'C#m', crossHarp: 'Abm', major: ['A', 'B', 'Eb', 'E'], minor: ['Db', 'C#', 'F#', 'G#'], dim: ['D#', 'Eb'] },

  { key: 'F#', crossHarp: 'C#', major: ['Gb', 'F#', 'B', 'Db', 'C#'], minor: ['Ab', 'G#', 'Bb', 'A#', 'Eb', 'D#'], dim: ['F'] },
  { key: 'F#m', crossHarp: 'C#m', major: ['D', 'E', 'G#', 'A'], minor: ['F#', 'Gb', 'B', 'C#'], dim: ['G#', 'Ab'] }
]

const chords = [
  ['A'],
  ['Bb', 'A#'],
  ['B'],
  ['C'],
  ['C#', 'Db'],
  ['D'],
  ['Eb', 'D#'],
  ['E'],
  ['F'],
  ['F#', 'Gb'],
  ['G'],
  ['Ab', 'G#']
]
const getChordType = (chord) => {
  if (chord.toLowerCase().indexOf('dim') > -1 || chord.toLowerCase().indexOf('°') > -1) {
    return 'dim'
  }
  if (chord.toLowerCase().indexOf('m') > -1) {
    return 'minor'
  }
  return 'major'
}

const getChordPoints = (key, chordType) => {
  // minor key gives higher points for minor chord matches
  if (key.endsWith('m')) {
    switch (chordType) {
      case 'dim': return 0.8
      case 'major': return 1
      default: return 1.2
    }
  }
  // major key gives higher points for major chord matches
  switch (chordType) {
    case 'dim': return 0.8
    case 'minor': return 1
    default: return 1.2
  }
}

const chordRegex = /\{\S+?\}/gi

export const getSongChords = (songContent) => {
  const matches = songContent.match(chordRegex)
  const uniqueChords = _uniq(matches)

  return _map(uniqueChords, uc => uc.replace(/\{|\}/g, ''))
}

export const getKeyChords = (inKey) => {
  const match = _find(songKeys, songKey => songKey.key === inKey)
  if (!match) {
    return []
  }

  const keyChords = []
  if (inKey.endsWith('m')) {
    _each(match.minor, minorChord => {
      keyChords.push(`${minorChord}m`)
    })
    _each(match.major, mjr => keyChords.push(mjr))
  } else {
    _each(match.major, mjr => keyChords.push(mjr))
    _each(match.minor, minorChord => {
      keyChords.push(`${minorChord}m`)
    })
  }

  _each(match.dim, dim => keyChords.push(`${dim}dim`))

  return keyChords
}

const replaceBrackets = (str) => {
  try {
    return str.replace(/\{|\}/g, '')
  } catch (e) {
    console.log('error in detect-key replaceBrackets: ', str)
    return str
  }
}

export default (songContent) => {
  const uniqueChords = getSongChords(songContent)
  const keyScores = JSON.parse(JSON.stringify(songKeys))

  _each(uniqueChords, chord => {
    const chordPlus = replaceBrackets(chord)
    const two = chordPlus.substring(0, 2)
    const one = chordPlus.substring(0, 1)
    const oneChord = _find(chords, possibles => possibles.includes(one))
    const twoChord = _find(chords, possibles => possibles.includes(two))
    const strippedChord = oneChord ? oneChord[0] : _find(twoChord, v => v === two)

    // is it maj, min, dim ?
    const chordType = getChordType(chord)

    // add it to the keys record/scores
    _each(keyScores, key => {
      if (!key.hits) {
        key.hits = []
        key.score = 0
      }
      if (key[chordType].includes(strippedChord)) {
        // add to score
        if (!key.hits.includes(`${strippedChord}-${chordType}`)) {
          key.score += getChordPoints(key.key, chordType)
          key.hits.push(`${strippedChord}-${chordType}`)
        }
      }
    })
  })

  // sort keys by score
  keyScores.sort((a, b) => a.score < b.score ? 1 : -1)

  const top = []
  top.push(keyScores[0])
  for (let x = 1; x < keyScores.length; x++) {
    if (keyScores[x].score === top[0].score) {
      top.push(keyScores[x])
    } else {
      break
    }
  }

  if (!top[0].score) {
    return []
  }

  return top
}
