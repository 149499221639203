import React from 'react'
import styled from 'styled-components'

import { FaLongArrowAltLeft as LeftArrow, FaLongArrowAltRight as RightArrow } from 'react-icons/fa'

const Arrow = styled.span`
  color: ${({ active, theme }) => active ? theme.secondary : theme.tertiary};
  cursor: ${props => props.enabled ? 'pointer' : 'default'};
  opacity: ${props => props.enabled ? '1' : '.3'};
`

export default ({ direction, enabled, onClick }) => {
  if (direction === 'left') {
    return <Arrow enabled={enabled} onClick={() => enabled ? onClick() : e => e}><LeftArrow size={30} /></Arrow>
  } else {
    return <Arrow enabled={enabled} onClick={() => enabled ? onClick() : e => e}><RightArrow size={30} /></Arrow>
  }
}
