import _each from 'lodash.foreach'
import moment from 'moment'

export default songs => {
  let total = 0
  let missing = 0
  _each(songs, song => {
    const thisMoment = moment(song.duration, 'mm:ss')
    if (thisMoment.isValid()) {
      const thisSeconds = thisMoment.diff(moment().startOf('day'), 'seconds')
      total += thisSeconds
    } else {
      missing++
    }
  })

  const totalFormated = moment.utc(1000 * total).format('HH:mm:ss')

  return {
    totalFormated,
    missing
  }
}
