import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { axios } from '../../context/auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { PrimaryButton } from '../../styled/button'
import InputGroup from '../../styled/input-group'
import FormError from '../../styled/form-error'

const EditShowSchema = Yup.object().shape({
  title: Yup.string().min(2).max(150).required().label('Show Title')
})

const CreateShow = () => {
  const navigate = useNavigate()
  const save = useCallback(async (values) => {
    try {
      const response = await axios.post('/api/shows', {
        title: values.title
      })
      console.log('response', response)
      navigate(`/shows/${response.data._id}`)
    } catch (err) {
      if (err.response.status === 400) {
        setErrors(err.response.data)
      } else {
        console.log('err', err.response)
      }
    }
  })

  const { errors, values, handleChange, handleSubmit, setErrors, isSubmitting } = useFormik({
    initialValues: {
      title: ''
    },
    validationSchema: EditShowSchema,
    onSubmit: (formValues) => save(formValues)
  })

  return (
    <>
      <h3>Create Show</h3>
      <InputGroup>
        <label htmlFor='title'>Title: </label>
        <input
          type='text'
          name='title'
          value={values.title}
          onChange={handleChange}
        />
        {errors.title && <FormError>{errors.title}</FormError>}
      </InputGroup>
      <PrimaryButton disabled={isSubmitting} onClick={handleSubmit}>Create Show</PrimaryButton>
    </>
  )
}
export default CreateShow
