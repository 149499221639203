import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { axios } from '../../context/auth'
import LinkButton from '../../styled/link-button'
import _map from 'lodash.map'

const RehearsalMenu = ({ rehearsal, runRehearsal }) => {
  const navigate = useNavigate()

  // const [showContent, setShowContent] = useState()
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState()
  const [activeRehearsals] = useState()

  const fetchGroups = useCallback(async () => {
    try {
      const response = await axios.get('/api/groups')
      setGroups(response.data)
      setSelectedGroup(response.data[0]._id)
    } catch (err) {

    }
  })

  useEffect(() => {
    fetchGroups()
  }, ['-'])

  // const toggleDropdown = useCallback(async () => {
  //   if (!showContent) {
  //     const userRehearsals = await axios.get('/api/rehearsals')
  //     setActiveRehearsals(_map(userRehearsals, userRehearsal => userRehearsal.active))
  //   } else {
  //     setActiveRehearsals([])
  //   }
  //   setShowContent(!showContent)
  // })

  // const join = useCallback((id) => {
  //   navigate(`/rehearse/${id}`)
  // })

  const stop = useCallback(async () => {
    //  console.log('ending rehearsal')
    if (rehearsal) {
      await axios.delete(`/api/rehearsals/${rehearsal._id}`)
    }
  })

  const start = useCallback(async () => {
    // console.log('starting!')
    const newRehearsalResponse = await axios.post('/api/rehearsals', { groupId: selectedGroup })
    // console.log('newRehearsal', newRehearsalResponse.data)
    // need to set this in the rehearsal context
    runRehearsal(newRehearsalResponse.data)
    // then redirect home
    navigate('/')
  })

  return (
    <div>
      {rehearsal && <div>Running rehearsal for {rehearsal.group.title}<br /><LinkButton onClick={stop}>Stop</LinkButton></div>}
      {!rehearsal && activeRehearsals && activeRehearsals.length > 0 && (
        <div>
          <h4>Rehearsals in progress!</h4>
          {_map(activeRehearsals, activeRehearsal => (
            <div key={activeRehearsal._id}><LinkButton onClick=''>Join {activeRehearsal.group.title} Rehearsal</LinkButton></div>
          ))}
        </div>
      )}
      {!rehearsal && (
        <div>
          <select name='selectedGroup' onChange={(e) => setSelectedGroup(e.target.value)}>
            {_map(groups, group => <option key={group._id} value={group._id}>{group.title}</option>)}
          </select>
          <button onClick={start}>Start Rehearsing</button>
        </div>
      )}
    </div>
  )
}

export default RehearsalMenu
