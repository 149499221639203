import { DEVICE_ID_KEY } from '../constants'

const setItem = (key, item) => {
  try {
    window.localStorage.setItem(key, item)
  } catch (e) {
    // don't clear the device id, but clear all others i guess?
    for (let i = 0; i < window.localStorage.length; i++) {
      if (window.localStorage.key(i) !== DEVICE_ID_KEY) {
        window.localStorage.removeItem(window.localStorage.key(i))
      }
    }
    console.log('could not set item in localstorage', e)
  }
}

const getItem = (key) => {
  return window.localStorage.getItem(key)
}

const clear = () => {
  window.localStorage.clear()
}

export default {
  setItem,
  getItem,
  clear
}
