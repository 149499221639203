import _findIndex from 'lodash.findindex'
import _find from 'lodash.find'
import _map from 'lodash.map'

const chords = [
  ['A'],
  ['Bb', 'A#'],
  ['B', 'Cb'],
  ['C', 'B#'],
  ['C#', 'Db'],
  ['D'],
  ['Eb', 'D#'],
  ['E', 'Fb'],
  ['F'],
  ['F#', 'Gb'],
  ['G'],
  ['Ab', 'G#']
]

// This entire fn can probably be replaced with a single regex match
export const findChordBase = (chord) => {
  const two = chord.substring(0, 2)
  const one = chord.substring(0, 1)
  const oneChord = _find(chords, possibles => possibles.includes(one))
  const twoChord = _find(chords, possibles => possibles.includes(two))
  const base = _find(twoChord, v => v === two) ? _find(twoChord, v => v === two) : oneChord ? oneChord[0] : undefined

  return base
}

export const getChordParts = chord => {
  const base = findChordBase(chord)
  const other = chord.split(base)

  return [base, other[1]]
}

export default (chordPlus, steps) => {
  if (!steps || steps === 0) {
    return chordPlus
  }

  const slashSplit = chordPlus.split('/')

  const slashParts = _map(slashSplit, slashChordPlus => {
    const chord = findChordBase(slashChordPlus)
    if (!chord) {
      return slashChordPlus
    }

    const current = _findIndex(chords, possibles => possibles.includes(chord))
    const minSteps = steps % chords.length

    let newIndex = current + minSteps

    if (newIndex < 0) {
      newIndex += chords.length
    }
    if (newIndex > chords.length - 1) {
      newIndex -= chords.length
    }
    try {
      return slashChordPlus.replace(chord, chords[newIndex][0])
    } catch (e) {
      console.log('error in transpose-chord slashParts map: ', e)
      return slashChordPlus
    }
  })

  return slashParts.join('/')
}
