import styled from 'styled-components'

export default styled.div`
  margin-bottom: 1.5em;

  h1 {
    font-size: 1.5em;
    font-weight: 800;
    margin-bottom: 0.1em;
  }

  h2 {
    font-size: 1.2em;
    font-style: italic;
    font-weight: 300;
  }
`
