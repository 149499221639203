import styled from 'styled-components'

const Base = styled.div`
white-space: pre-wrap;

.line {
  display: block;
  margin-bottom: 0.5em;
  position: relative;
}

.anchor {
  position: relative;

  ${({ songTheme }) => {
    switch (songTheme) {
      case 'lyrics-only':
        return `
            display: none;
          `

      case 'above':
        return `
        margin: 10px 0 45px;
        display: inline-block;
        `

      default:
        return ''
    }
  }}
}

  .chord {
    padding: 0 0.4em;
    border: 1px solid ${({ theme }) => theme.secondary};
    border-radius: 0.2em;
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.chordColor};
    
    ${({ songTheme }) => {
    switch (songTheme) {
      case 'lyrics-only':
        return `
              display: none;
            `

      case 'above':
        return `
          position: absolute;
          top: -40px;
        `

      default:
        return ''
    }
  }}
  }
`

export const ScrollableSongBody = styled(Base)`
  padding: .375rem .75rem;
  height: 80vh;
  scroll: auto;
  overflow: hidden;
  line-height: 1.5;
`

export const ChordButton = styled.button`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: transparent;
  cursor: pointer;
  left: ${({ left, songTheme }) => songTheme === 'above' ? left || 0 : 0}px;
`

export default Base
