import React, { useCallback, useEffect, useState } from 'react'
import _map from 'lodash.map'
import { axios } from '../../context/auth'
import styled from 'styled-components'

import PermissionsSearchContainer from '../../styled/perm-search-container'
import InputGroup from '../../styled/input-group'
import LinkButton from '../../styled/link-button'

const FlexRow = styled.div`
  @media(min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
const SearchBox = styled(InputGroup)`
  width: 100%;
  @media(min-width: 1200px) {
    width: 66%;
  }
`
const PermBox = styled(InputGroup)`
  @media(min-width: 1200px) {
    width: 33%;
  }
  width: 100%;
`
let debounce
/**
 * Allows the user to add and remove user and group permissions on
 * the current object
 */
const UserPicker = ({ roles, isGroup, addGroup, addUser }) => {
  const [userRole, setUserRole] = useState(roles[0])
  const [users, setUsers] = useState([])
  const [groups, setGroups] = useState([])
  const [term, setTerm] = useState('')

  useEffect(() => { // const search =
    clearTimeout(debounce)
    if (term.length < 4) {
      return
    }
    debounce = setTimeout(async () => {
      try {
        const userResponse = await axios.get(`/api/user/search/${term}`)
        setUsers(userResponse.data)

        // if we're viewing a group, don't search for groups
        if (!isGroup) {
          const groupsResponse = await axios.get(`/api/groups/search/${term}`)
          setGroups(groupsResponse.data)
        }
      } catch (err) {

      }
    }, 500)
  }, [term])

  const handleRoleChange = useCallback((event) => {
    setUserRole(event.target.value)
  })

  const handleTermChange = useCallback((event) => {
    setTerm(event.target.value)
  })

  const thisAddUser = useCallback((userId, e) => {
    addUser(userId, userRole)
  })

  const thisAddGroup = useCallback((groupId, e) => {
    addGroup(groupId, userRole)
  })

  return (
    <PermissionsSearchContainer>
      <FlexRow>
        <SearchBox>
          <label htmlFor='term'>Search Users/Groups</label>
          <input
            type='text'
            name='term'
            value={term}
            onChange={handleTermChange}
          />
        </SearchBox>
        <PermBox>
          <label htmlFor='userRole'>Role</label>
          <select name='userRole' onChange={handleRoleChange}>
            {_map(roles, role => {
              return <option key={role} value={role}>{role}</option>
            })}
          </select>
        </PermBox>
      </FlexRow>
      <div>
        {_map(users, objUser => {
          return <div key={objUser._id}>{objUser.email} <LinkButton onClick={(e) => thisAddUser(objUser._id, e)}>Add User</LinkButton></div>
        })}
        {_map(groups, objGroup => {
          return <div key={objGroup._id}>{objGroup.title} <LinkButton onClick={(e) => thisAddGroup(objGroup._id, e)}>Add Group</LinkButton></div>
        })}
      </div>
    </PermissionsSearchContainer>
  )
}

export default UserPicker
