import React, { useCallback, useEffect, useState } from 'react'
import { axios } from '../../context/auth'
import _includes from 'lodash.includes'
import _map from 'lodash.map'
import _remove from 'lodash.remove'

import CommonTags from './common'

import OptionsContainer, { Title } from '../../styled/options-container'

/**
 * Custom component for getting and saving tags on a song
 * wraps common tags component
 *
 * @param {} param0
 * @returns
 */
// let songTagsSource
// let tagsSource
const SongTags = ({ songid, canEdit }) => {
  const [tags, setTags] = useState()
  const [suggestions, setSuggestions] = useState([])

  const refreshTags = useCallback(async () => {
    try {
      // songTagsSource = axios.CancelToken.source() { cancelToken: songTagsSource.token }
      const response = await axios.get(`/api/songs/${songid}/tags`)
      setTags(_map(response.data, dbTag => dbTag.tag.label))
    } catch (err) {
      console.log(err)
    }
    try {
      // tagsSource = axios.CancelToken.source() { cancelToken: tagsSource.token }
      const response = await axios.get('/api/tags')
      setSuggestions(_map(response.data, userTag => userTag.label))
    } catch (err) {
      console.log(err)
    }
  })

  const handleAddition = useCallback(async (tag) => {
    const newTag = tag.replace(/[^a-z-_0-9+]+/gi, '').toLowerCase()

    if (!_includes(suggestions, newTag)) {
      // we need to create this tag first
      await axios.post('/api/tags', { label: newTag })
    }

    await axios.post(`/api/songs/${songid}/tags`, { tag: newTag })
    setTags(_includes(tags, tag) ? tags : [...tags, newTag])
    setSuggestions(_includes(suggestions, tag) ? suggestions : [...suggestions, newTag])
  })

  const handleDelete = useCallback(async (tag) => {
    if (!canEdit) {
      return
    }

    await axios.delete(`/api/songs/${songid}/tags/${tag}`)
    const newTags = _remove(tags, currentTag => currentTag !== tag)
    setTags(newTags)
  })

  useEffect(() => {
    setTimeout(() => {
      refreshTags()
    }, 500)
    return () => {
      // if (songTagsSource) {
      //   songTagsSource.cancel('song tags fetch canceled, song-tags unmounted')
      // }
      // if (tagsSource) {
      //   tagsSource.cancel('song tags fetch canceled, song-tags unmounted')
      // }
    }
  }, ['-'])

  if (!tags) {
    return 'Loading tags...'
  }

  return (
    <OptionsContainer>
      <Title>Tags</Title>
      <CommonTags
        tags={tags}
        suggestions={suggestions}
        songid={songid}
        canEdit={canEdit}
        handleAddition={handleAddition}
        handleDelete={handleDelete}
        placeholder='Add a tag'
      />
    </OptionsContainer>
  )
}

export default SongTags
