import React from 'react'
import styled from 'styled-components'

import SongContent from './content'

import SongInfoHeader from '../../styled/song-info-header'
import Meta from '../../styled/song-meta'
import Content from '../../styled/song-note-content'

const Divider = styled.div`
  display: block;
  width: 100%;
  height: 1em;
  background-color: ${({ theme }) => theme.primary};
  margin-bottom: 10px;
`

const Song = styled.div`
  display: inline-block;
  width: 100%;
  @media(min-width: 1024px) {
    width: 65%;
  }
  padding-bottom: 25px;
`

const ExportSongView = ({ song }) => {
  return (
    <Song>
      <Divider />
      <SongInfoHeader>
        <h1>{song.title}</h1>
        <h2>{song.artist}</h2>
      </SongInfoHeader>
      {song && song.key && <Meta>Key: {song.key}</Meta>}
      {song && song.tempo && <Meta>Tempo: {song.tempo}</Meta>}
      {song && song.note && <Meta>Note: <Content dangerouslySetInnerHTML={{ __html: song.note }} /></Meta>}
      <SongContent
        songTheme='inline'
        content={song.content}
        transposeSteps={song.transposed || 0}
      />
    </Song>
  )
}

export default ExportSongView
