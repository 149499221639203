import React from 'react'
import styled from 'styled-components'

import SongInfoHeader from './song-info-header'
import { Delete, Edit, Export, Copy } from './icon-link-button'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const TitleGroup = ({ title, subtitle, onEdit, onDelete, onExport, onCopy, children }) => (
  <SongInfoHeader>
    <Container>
      <div>
        <h1>{title}
          {onEdit && <Edit onClick={onEdit} title='Edit' />}
          {onCopy && <Copy onClick={onCopy} title='Copy' />}
          {onExport && <Export onClick={onExport} title='Export' />}
        </h1>
      </div>
      {children}
      <div>
        {onDelete && <Delete onClick={onDelete} title='Delete' />}
      </div>
    </Container>
    {subtitle && <h2>{subtitle}</h2>}
  </SongInfoHeader>
)

export default TitleGroup
