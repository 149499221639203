import { createConfirmation } from 'react-confirm'
import ConfirmBBox from './component'

// create confirm function
const confirm = createConfirmation(ConfirmBBox, 1)

// This is optional. But I recommend to define your confirm function easy to call.
export default (confirmation, options = {}, ok) => {
  // You can pass whatever you waant to the component. These arguments will be your Component's props
  return confirm({ confirmation, options, ok }, ok)
}
