const toProperCase = str => {
  return str.replace(/\w\S*/g, txt => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
}

export default (instrumentName) => {
  try {
    const newName = instrumentName.replace(/_/g, ' ')
    return toProperCase(newName)
  } catch (e) {
    console.log('error in title-case-instrument: ', instrumentName)
    return instrumentName
  }
}
