import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { axios } from '../../context/auth'

import OptionsContainer, { MarginButton, Title } from '../../styled/options-container'

import InputGroup from '../../styled/input-group'
import FormError from '../../styled/form-error'
import Content from '../../styled/song-note-content'

const formatNote = (note) => {
  try {
    return note.replace(/(\r\n|\n|\r)/gm, '<br />')
  } catch (e) {
    return note
  }
}

const CustomInputGroup = styled(InputGroup)`
  textarea {
    min-height: 10vh;
  }
`
const FlexHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

let noteSource

const UserSongNote = ({ song }) => {
  const [editNote, setEditNote] = useState(song.note)
  const [editing, setEditing] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    return () => {
      if (noteSource) {
        noteSource.cancel('Cancel song note update, song note unmounted')
      }
    }
  }, ['-'])

  const save = useCallback(async () => {
    try {
      // noteSource = axios.CancelToken.source() noteSource.token
      await axios({
        url: `/api/songs/${song._id}/note`,
        method: 'PATCH',
        data: {
          content: editNote === '' ? ' ' : editNote
        },
        cancelToken: ''
      })
      setEditing(false)
      setErrors({})
    } catch (err) {
      if (err.response.status === 400) {
        // field validations
        setErrors(err.response.data)
      } else {
        // something went wrong, generic error
        console.log(err)
      }
    }
  }, [editNote])

  const editClick = useCallback((e) => {
    if (editing) {
      save()
    } else {
      setEditing(!editing)
    }
  }, [editing, editNote])

  return (
    <OptionsContainer>
      <FlexHeader>
        <div><Title>My Notes</Title></div>
        <div><MarginButton onClick={editClick}>{editing ? 'Save' : 'Edit'}</MarginButton></div>
      </FlexHeader>
      {editing && (
        <CustomInputGroup><textarea onChange={(e) => setEditNote(e.target.value)} value={editNote} /></CustomInputGroup>
      )}
      {!editing && <Content dangerouslySetInnerHTML={{ __html: formatNote(editNote) }} />}
      {errors.content && <FormError>{errors.content}</FormError>}
    </OptionsContainer>
  )
}

export default UserSongNote
