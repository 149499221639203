import styled from 'styled-components'

export const ListControls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export default styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  max-width: 900px;
  margin: auto;
  
  .songListHeader {
    text-align: left;
    width: 100%;
    display: inline-block;
    margin-bottom: 2em;
    border-bottom: 2px solid ${({ theme }) => theme.primary};

    h2 {
      float: left;
    }
  }

  .controls {
    float: right;
    padding-top: 20px;
    font-size: 0.8em;
  }

  .nextPrevPage {
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
  }
`
