import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { AuthProvider, AuthConsumer } from '../context/auth'

const WelcomeContainer = styled.div`
  max-width: 600px;
`
const Marketing = styled.div`
  display: none;
`

const MarketingItem = styled.div`
  width: 100%;
  @media screen and (min-width: 1024px) {
    width: 33%;
  }
`

const Home = () => {
  return (
    <>
      <AuthProvider>
        <AuthConsumer>
          {({ user }) => {
            if (user) {
              window.location.href = '/songs'
            }
            return (
              <WelcomeContainer>
                <h2>
                  Making it easy to learn, write, collaborate, and share all your favorite songs. Basically you can think of it as your new online songbook.
                  {' '}
                  {(!user)
                    ? <Link to='/login' className='menu-item'>Login</Link>
                    : <Link to='/songs' className='menu-item'>Check out your songs</Link>}
                </h2>

                <Marketing>
                  <MarketingItem>
                    <h3>Create</h3>
                    <p>Build your song book with an easy set of tools to create and organize your songs and setlists.</p>
                  </MarketingItem>
                  <MarketingItem>
                    <h3>Share</h3>
                    <p>Easily share your songs and setlists with friends or groups of friends with granular permissions for viewing, editing and ownership of content.</p>
                  </MarketingItem>
                  <MarketingItem>
                    <h3>Play</h3>
                    <p>Practice and perform with an easy to use set of tools: setlist song navigation, metronome, last practiced date, and dark mode theme</p>
                  </MarketingItem>
                </Marketing>
              </WelcomeContainer>
            )
          }}
        </AuthConsumer>
      </AuthProvider>
    </>
  )
}

export default Home
