import _get from 'lodash.get'

const defaultTheme = {
  primary: '#23374d',
  primaryContrast: '#fff',
  secondary: '#1089ff',
  tertiary: '#1089ff',
  background: '#fff',
  input: '#495057',
  inputBackground: '#fff',
  borderColor: 'rgba(218, 218, 218, 1)',
  borderColorDark: 'rgba(218, 218, 218, 0.6)',
  secondaryBackground: '#eaeaea',
  bodyText: '#444',
  chordColor: '#fff',

  // layout
  margin: '1em'
}

const darkTheme = {
  primary: '#ca3e47',
  primaryContrast: '#ccc',
  secondary: '#FF8080',
  tertiary: '#B33636',
  background: '#222',
  input: '#dadada',
  inputBackground: 'rgba(179, 54, 54, 0.2)',
  borderColor: 'rgba(128, 51, 51, 1)',
  borderColorDark: 'rgba(128, 51, 51, 0.6)',
  secondaryBackground: '#444',
  bodyText: '#ccc',
  chordColor: '#ccc',

  // layout
  margin: '1em'
}

const themes = {
  default: defaultTheme,
  dark: darkTheme
}

export default (user) => {
  const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

  let theme = 'default'

  const userTheme = _get(user, 'settings.themePreference')
  if (!userTheme || userTheme === 'auto') {
    theme = isDarkMode ? 'dark' : 'default'
  }

  if (userTheme && userTheme !== 'auto') {
    theme = userTheme
  }

  return themes[theme] || themes.default
}
