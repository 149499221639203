import React from 'react'
import _map from 'lodash.map'
import { Link } from 'react-router-dom'

import Song from '../songs/song'

import Duration from './duration'

const ViewShowSetList = ({ setList, idx }) => {
  return (
    <div className='ViewSetList'>
      <h1><Link to={`/setlists/${setList._id}`}>Set {idx + 1}</Link></h1>
      {_map(setList.songs, setListSong => {
        return <Song key={setListSong._id} song={setListSong} setlistid={setList._id} />
      })}

      <Duration songs={setList.songs} />
    </div>
  )
}

export default ViewShowSetList
