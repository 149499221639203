import styled from 'styled-components'

const MetaSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  label {
    margin-right: 5px;
    margin-bottom: 0px;
  }
  margin-bottom: 10px;

  select {
    float: left;
    display: block;
    color: ${({ theme }) => theme.input};
    background-clip: padding-box;
    border: 1px solid ${({ theme }) => theme.borderColor};
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: ${({ theme }) => theme.inputBackground};
  }
`

export default MetaSelectContainer
