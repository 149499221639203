import styled from 'styled-components'

export default styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2em;

  h1 {
    color: ${({ theme }) => theme.primary};
    text-shadow: 2px 0px ${({ theme }) => theme.secondary};
    font-weight: 800;
    text-transform: lowercase;
    font-size: 2em;
    font-style: italic;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  nav {
    padding: 0;

    a {
      border-bottom: 1px dashed ${({ theme }) => theme.secondary};
      margin-left: 1em;
      padding: 0;
    }
  }
`
