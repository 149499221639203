import styled from 'styled-components'

export const ConfirmModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`

export const ConfirmContent = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position:fixed;
  background: white;
  ${({ width = '50%' }) => `width: ${width};`}
  ${({ height = '50%' }) => `width: ${height};`}
  min-height: 300px;
  min-width: 400px;
  top:50%;
  left:50%;
  padding: 15px;
  transform: translate(-50%,-50%);
  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
  }
`

export const ConfirmMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
  font-size: 1.5em;
  font-weight: normal;
`

export const ConfirmFocal = styled.div`
  display: inline-block;
  font-style: italic;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
`

export const ConfirmButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`
