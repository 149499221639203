import React, { useCallback, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import _get from 'lodash.get'
import _includes from 'lodash.includes'
import styled from 'styled-components'

import OptionsContainer, { MarginButton, Title } from '../../styled/options-container'
import { axios, context as AuthContext } from '../../context/auth'

const FlexHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export default ({ song }) => {
  const { user: { linked } } = useContext(AuthContext)
  const isSpotifyLinked = _includes(linked, 'spotify')

  const [searchResults, setSearchResults] = useState()
  const [isSearching, setIsSearching] = useState(false)
  const [showContent, setShowContent] = useState(false)

  const searchForSong = useCallback(async () => {
    setIsSearching(true)
    const searchResults = await axios.get(`/api/spotify/search?title=${song.title}&artist=${song.artist}`).then(resp => resp.data)
    setSearchResults(searchResults)
    setIsSearching(false)
  }, [setSearchResults, setIsSearching, song])

  const engage = useCallback(() => {
    setShowContent(!showContent)
    if (isSpotifyLinked) {
      searchForSong()
    }
  }, [showContent, setShowContent, searchForSong, isSpotifyLinked])

  const showLinkFirst = showContent && !isSpotifyLinked
  const showLoading = showContent && isSpotifyLinked && isSearching
  const showPlayer = showContent && isSpotifyLinked && searchResults && _get(searchResults, 'tracks.items[0].id')
  const songNotFound = showContent && isSpotifyLinked && searchResults && !_get(searchResults, 'tracks.items[0].id')

  return (
    <OptionsContainer>
      <FlexHeader>
        <div><Title>Spotify</Title></div>
        <div><MarginButton onClick={engage}>{showContent ? 'Hide' : 'View'}</MarginButton></div>
      </FlexHeader>
      {showLinkFirst && <div>Link your spotify account first in <Link to='/settings'>Settings</Link></div>}
      {showLoading && <div>Loading...</div>}
      {showPlayer && <iframe src={`https://open.spotify.com/embed/track/${searchResults.tracks.items[0].id}`} width='300' height='380' frameborder='0' allowtransparency='true' allow='encrypted-media' />}
      {songNotFound && <div>Sorry.  Song not found on Spotify</div>}
    </OptionsContainer>
  )
}
