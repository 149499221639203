import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { axios } from '../../context/auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { pageView, PAGES } from '../../utils/analytics-tracker'

import InputGroup from '../../styled/input-group'
import AuthFormContainer from '../../styled/auth-form-container'
import { PrimaryButton } from '../../styled/button'
import FormError from '../../styled/form-error'

const PassResetSchema = Yup.object().shape({
  email: Yup.string().min(6).max(150).email().required()
})

const ForgotPassword = () => {
  const navigate = useNavigate()

  const { values, errors, touched, setErrors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: PassResetSchema,
    onSubmit: (formValues) => submit(formValues)
  })

  useEffect(() => {
    pageView(PAGES.PASSWORD_RESET)
  }, ['-'])

  const submit = useCallback(async (values) => {
    try {
      await axios.post('/auth/password-reset-request', {
        email: values.email
      })
      navigate('/confirm-email')
    } catch (err) {
      if (err.response.status === 400) {
        setErrors(err.response.data)
      } else {
        console.log(err)
      }
    }
  })

  return (
    <>
      <h1>Forgot/Reset Password</h1>
      <AuthFormContainer>
        <InputGroup>

          <p>Enter your email address and we'll send you a link that will allow to you set a new password.</p>
          <label htmlFor='username'>Email Address: </label>
          <input
            type='email'
            name='email'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.email && errors.email && <FormError>{errors.email}</FormError>}
          <PrimaryButton onClick={handleSubmit}>Send Reset Email</PrimaryButton>
        </InputGroup>
      </AuthFormContainer>
    </>
  )
}

export default ForgotPassword
