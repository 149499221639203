import React, { useCallback, useState } from 'react'
import { Routes, Route, Link } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'
import { slide as BurgerMenu } from 'react-burger-menu'

import LoginForm from './components/auth/login-form'
import RegisterForm from './components/auth/register-form'
import CheckEmail from './components/auth/check-email'
import ConfirmRegistration from './components/auth/confirm-registration'
import ForgotPassword from './components/auth/forgot-password'
import NewPassword from './components/auth/new-password'

import Home from './components/Home'
import Header from './styled/header'

import Settings from './components/settings/edit'

import SongList from './components/songs/list'
import SongEdit from './components/songs/edit'
import SongView from './components/songs/view'

import SetListList from './components/setlists/list'
import SetListEdit from './components/setlists/edit'
import SetListView from './components/setlists/view'
import SetListExport from './components/setlists/export'

import ShowCreate from './components/shows/create'
import ShowEdit from './components/shows/edit'
import ShowView from './components/shows/view'

import GroupList from './components/groups/list'
import GroupEdit from './components/groups/edit'
import GroupView from './components/groups/view'

import RehearseView from './components/rehearse/view'
import Rehearse from './components/rehearse'

import getTheme from './styled/theme'

import Loader from './styled/loader'

import { AuthProvider, AuthConsumer } from './context/auth'
import { SongListProvider } from './context/song-list'

import BodyComponent from './styled/body'

const StyledBurgerMenu = styled.div`
  .bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }

  .bm-burger-bars {
  background: ${({ theme }) => theme.primary};
  }

  .bm-burger-bars-hover {
    background: ${({ theme }) => theme.secondary};
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu-wrap {
    position: fixed;
    height: 100vh;
    top: 0;
  }

  .bm-menu {
    background: ${({ theme }) => theme.secondaryBackground};
    padding: 2.0em 1.5em 0;
    font-size: 1.15em;
  }

  .bm-morph-shape {
    fill: ${({ theme }) => theme.secondaryBackground};
  }

  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  .bm-item {
    display: block;
    margin-bottom: 1em;
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
  }
`

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleStateChange = useCallback((state) => {
    setMenuOpen(state.isOpen)
  })

  const closeMenu = useCallback(() => {
    setMenuOpen(false)
  })

  return (
    <AuthProvider>
      <AuthConsumer>
        {({ logout, user, refreshUser }) => (
          <SongListProvider user={user}>
            <ThemeProvider theme={getTheme(user)}>
              <>
                <BodyComponent className='App'>
                  <Header>
                    <h1><Link onClick={() => closeMenu()} to={(user) ? '/songs' : '/'} className='menu-item'>Tabify</Link></h1>
                    <StyledBurgerMenu>
                      <BurgerMenu
                        isOpen={menuOpen}
                        onStateChange={(state) => handleStateChange(state)}
                        right
                      >
                        {!user && <Link onClick={() => closeMenu()} to='/login' className='menu-item'>login</Link>}
                        {!user && <Link onClick={() => closeMenu()} to='/register' className='menu-item'>sign up</Link>}
                        {user && <Link onClick={() => closeMenu()} to='/songs' className='menu-item'>songs</Link>}
                        {user && <Link onClick={() => closeMenu()} to='/new-song' className='menu-item'>&nbsp;&nbsp;&nbsp;+ song</Link>}
                        {user && <Link onClick={() => closeMenu()} to='/setlists' className='menu-item'>set lists</Link>}
                        {user && <Link onClick={() => closeMenu()} to='/groups' className='menu-item'>groups</Link>}
                        {user && <Link onClick={() => closeMenu()} to='/settings' className='menu-item'>settings</Link>}
                        {/* { user && <Link onClick={() => closeMenu()} to='/rehearse' className='menu-item'>rehearse</Link> } */}
                        {user && <Link to='#' className='menu-item' onClick={logout}>logout</Link>}
                      </BurgerMenu>
                    </StyledBurgerMenu>
                  </Header>
                  {/*  ROUTES */}
                  <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='loader' element={<Loader />} />
                    <Route path='login' element={<LoginForm />} />
                    <Route path='register' element={<RegisterForm />} />
                    <Route path='confirm-email' element={<CheckEmail />} />
                    <Route path='confirm-registration/:token' element={<ConfirmRegistration />} />
                    <Route path='password-reset' element={<ForgotPassword />} />
                    <Route path='password-reset/confirm/:token' element={<NewPassword />} />
                    {user &&
                      <>
                        <Route path='settings' element={<Settings />} />
                        <Route path='songs' element={<SongList />} />
                        <Route path='new-song' element={<SongEdit />} />
                        <Route path='rehearse' element={<Rehearse />} />
                        <Route path='songs/:songid' element={<SongView />} />
                        <Route path='songs/:songid/edit' element={<SongEdit />} />

                        <Route path='setlists' element={<SetListList />} />
                        <Route path='new-setlist' element={<SetListEdit />} />
                        <Route path='setlists/:setlistid' element={<SetListView />} />
                        <Route path='setlists/:setlistid/edit' element={<SetListEdit />} />
                        <Route path='setlists/:setlistid/export' element={<SetListExport />} />

                        <Route path='setlists/:setlistid/song/:songid' element={<SongView />} />

                        <Route path='new-show' element={<ShowCreate />} />
                        <Route path='shows/:showid' element={<ShowView />} />
                        <Route path='shows/:showid/edit' element={<ShowEdit />} />

                        <Route path='groups' element={<GroupList />} />
                        <Route path='new-group' element={<GroupEdit />} />
                        <Route path='groups/:groupid' element={<GroupView />} />
                        <Route path='groups/:groupid/edit' element={<GroupEdit />} />

                        <Route path='rehearse/:rehearsalid' element={<RehearseView />} />
                      </>}
                    {!user &&
                      <>
                        <Route path='songs/:songid' element={<LoginForm />} />
                        <Route path='setlists/:setlistid' element={<LoginForm />} />
                        <Route path='groups/:groupid' element={<LoginForm />} />
                      </>}
                  </Routes>
                </BodyComponent>
              </>
            </ThemeProvider>
          </SongListProvider>
        )}
      </AuthConsumer>

    </AuthProvider>
  )
}

export default App
