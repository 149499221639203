import React from 'react'
import _map from 'lodash.map'

import detectKey from './detect-key'

export default ({ songContent }) => {
  const detectedKeys = detectKey(songContent)
  if (detectedKeys.length === 0) {
    return null
  }
  const keyText = _map(detectedKeys, detectedKey => detectedKey.key).join(', ')
  return <div>{detectedKeys.length > 1 ? 'Possible Song Keys' : 'Detected Song Key'}: {keyText}</div>
}
