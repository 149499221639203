import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import _map from 'lodash.map'
import { axios } from '../../context/auth'

import { canEdit as canEditUtil, isOwner as isOwnerUtil } from '../../utils/auth'
import { event, pageView, ACTIONS, PAGES, CATEGORIES } from '../../utils/analytics-tracker'
import windowStorage from '../../utils/window-storage'

import CopySetList from './copy-setlist'
import confirm from '../confirmable'
import Duration from './duration'
import ObjectUsers from '../perms/object-users'
import Song from '../songs/song'

import { ConfirmFocal } from '../../styled/confirm-modal'
import TitleGroup from '../../styled/title-group'
import MetaSelectContainer from '../../styled/meta-select-container'

export const ADD_FIELDS = [
  { label: 'None', key: '' },
  { label: 'Tempo', key: 'tempo' },
  { label: 'First Chord', key: 'first' },
  { label: 'Key', key: 'key' },
  { label: 'Duration', key: 'duration' },
  { label: 'Last Rehearsed', key: 'lastRehearsed' },
  { label: 'Meta 1', key: 'meta1' },
  { label: 'Meta 2', key: 'meta2' },
  { label: 'Meta 3', key: 'meta3' },
  { label: 'Meta 4', key: 'meta4' }
]

const objectDisplay = obj => obj.title || obj.email

const confirmMessage = (removeObj) => {
  return (
    <div>Are you sure you want delete the set list
      <ConfirmFocal>{objectDisplay(removeObj)}</ConfirmFocal>
      ?
    </div>
  )
}

const ViewSetList = ({ setContext, setlistid }) => {
  const navigate = useNavigate()
  const { setlistid: matchSetlistId } = useParams()
  const useSetlistId = setlistid || matchSetlistId

  const [setlist, setSetlist] = useState()
  const [addFieldKey, setAddFieldKey] = useState(windowStorage.getItem('songMetaFieldName') || '')

  const [canEdit, setCanEdit] = useState(false)
  const [isOwner, setIsOwner] = useState(false)

  const refreshSetList = useCallback(async () => {
    try {
      const response = await axios.get(`/api/setlists/${useSetlistId}`)
      setSetlist(response.data)
      setCanEdit(canEditUtil(response.data))
      setIsOwner(isOwnerUtil(response.data))
    } catch (err) {
      console.log(err)
    }
  })

  const copySetList = useCallback(async () => {
    try {
      const response = await axios.get(`/api/setlists/${useSetlistId}/copy`)
      navigate(`/setlists/${response.data._id}/edit`)
    } catch (err) {
      console.log(err)
    }
  })

  const deleteSetList = useCallback(() => {
    confirm(confirmMessage(setlist)).then(
      (result) => {
        axios({
          url: `/api/setlists/${setlist._id}`,
          method: 'DELETE'
        }).then(() => {
          event(CATEGORIES.SONG, ACTIONS.SETLIST_DELETE)
          navigate('/setlists')
        })
      },
      (result) => {
        event(CATEGORIES.SONG, ACTIONS.SETLIST_DELETE_CANCEL)
      }
    )
  })

  const onAddFieldChange = (e) => {
    windowStorage.setItem('songMetaFieldName', e.target.value)
    setAddFieldKey(e.target.value)
  }

  const clickEdit = () => {
    navigate(`/setlists/${useSetlistId}/edit`)
  }

  const clickExport = () => {
    navigate(`/setlists/${useSetlistId}/export`)
  }

  useEffect(() => {
    pageView(PAGES.SETLIST_VIEW)
    refreshSetList()
    if (setContext) {
      setContext({
        songId: undefined,
        setlistId: useSetlistId
      })
    }
  }, ['-'])

  if (!setlist) {
    return <div>loading</div>
  }
  const roles = ['VIEWER', 'EDITOR', 'OWNER']
  return (
    <div className='ViewSetList'>
      <TitleGroup
        title={setlist.title}
        onEdit={canEdit && clickEdit}
        onDelete={isOwner && deleteSetList}
        onExport={clickExport}
        onCopy={copySetList}
      />
      <MetaSelectContainer>
        <label>Metadata:</label>
        <select id='addFieldSelect' value={addFieldKey} onChange={onAddFieldChange}>
          {_map(ADD_FIELDS, addField => {
            return (
              <option key={`metadata.${addField.key}`} value={addField.key}>{addField.label}</option>
            )
          })}
        </select>
      </MetaSelectContainer>
      {_map(setlist.songs, (setListSong, idx) => {
        return (
          <Song
            key={setListSong._id}
            song={setListSong}
            setlistid={useSetlistId}
            addFieldKey={addFieldKey}
          />
        )
      })}

      {setlist && <Duration songs={setlist.songs} />}
      {setlist && <CopySetList setlist={setlist} />}
      {setlist && isOwner && <ObjectUsers objectId={useSetlistId} category={CATEGORIES.SETLIST} roles={roles} apiPath='setlists' />}
    </div>
  )
}

export default ViewSetList
