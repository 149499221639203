import styled from 'styled-components'

const BaseButton = styled.button`
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`

export const PrimaryButton = styled(BaseButton)`
    color: ${({ theme }) => theme.primaryContrast};
    background-color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.secondary};
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    :disabled {
        cursor: not-allowed;
    }
`

export const SmallButton = styled(PrimaryButton)`
    font-size: .75rem;
    line-height: 1;
`

export default BaseButton
