import styled from 'styled-components'
import {
  marginBottom
} from '../variables'

const sharedInput = (theme) => `
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: ${theme.input};
  background-clip: padding-box;
  border: 1px solid ${theme.borderColor};
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  margin-bottom: ${marginBottom};
  background-color: ${theme.inputBackground};
`

export const Input = styled.input`${({ theme }) => sharedInput(theme)}`
export const Select = styled.select`${({ theme }) => sharedInput(theme)}`

export const SmallInputGroup = styled.div`
  width: 100%;
  display: block;
  margin-bottom: ${marginBottom};

  label {
    font-size: 0.7rem;
    text-transform: uppercase;
    display: block;
    padding-left: 0;
    font-weight: 600;
  }

  input,
  select,
  textarea {
    float: left;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.input};
    background-clip: padding-box;
    border: 1px solid ${({ theme }) => theme.borderColor};
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom: ${marginBottom};
    background-color: ${({ theme }) => theme.inputBackground};
  }

  .small {
    width: 25%;
    min-width: 80px;
    display: inline-block;
    padding-right: 15px;
  }

  select:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
  }

  textarea {
    min-height: 80vh;
  }
`

export default styled.div`
  width: 100%;
  display: block;
  margin-bottom: ${marginBottom};

  label {
    font-size: 0.7rem;
    text-transform: uppercase;
    display: block;
    padding-left: 0;
    font-weight: 600;
  }

  input,
  select,
  textarea {
    ${({ theme }) => sharedInput(theme)}
  }

  select:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
  }

  textarea {
    min-height: 80vh;
  }
`
