import React, { useState, useCallback, useEffect, useContext } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import _each from 'lodash.foreach'
// import _filter from 'lodash.filter'
import _map from 'lodash.map'

import { axios } from '../../context/auth'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { DraggableItem, DraggableContainer } from '../../styled/draggable'
import { PrimaryButton } from '../../styled/button'
import InputGroup, { Select } from '../../styled/input-group'
import OptionsContainer from '../../styled/options-container'
import { context as SongListContext } from '../../context/song-list'
import SearchTags from '../tags/search-tags'
import Duration from './duration'

import FormError from '../../styled/form-error'

import { pageView, PAGES } from '../../utils/analytics-tracker'
import timeDifference from '../../utils/time-difference'

const CustomInputGroup = styled(InputGroup)`
  width: 90%;
`

const TopContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TopLeft = styled.div` 
  width: 50%;
`

const TopRight = styled.div` 
  width: 50%;
`

const ComboSelect = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
`

const SearchGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`

const ListItemProp = styled.span`
  margin-left: 10px;
`

const CheckLabel = styled.div`
  display: inline-block;
  label {
    display: inline-block;
    padding-right: 15px;
    white-space: nowrap;
  }
  input {
    vertical-align: middle;
    margin-right: 5px;
  }
  label span {
    vertical-align: middle;
  }
`

const InListContainer = styled(DraggableContainer)`
  float: left;
  width: 48%;
  margin-right: 2%;

  > .target {
    min-height: 400px;
    background: ${({ theme }) => theme.secondaryBackground};
    border: 1px dashed ${({ theme }) => theme.borderColor};
    margin-bottom: 1em;
  }
`
const AvailableListContainer = styled(DraggableContainer)`
  float: left;
  width: 50%;
  min-height: 400px;
  background: ${({ theme }) => theme.secondaryBackground};
`

const ListsContainer = styled.div`
  max-width: 1100px;
`

const DraggableWrap = styled.div`

`

const StyledDraggableItem = styled(DraggableItem)`
  @media(min-width: 700px) {
    padding: 1em;
  }

  .grippy {
      content: '....';
      width: 20px;
      height: 22px;
      display: inline-block;
      overflow: hidden;
      line-height: 5px;
      cursor: move;
      vertical-align: middle;
      font-size: 12px;
      font-family: sans-serif;
      letter-spacing: 2px;
      background: ${({ theme }) => theme.secondaryBackground};
      color: ${({ theme }) => theme.bodyText};
      text-shadow: 1px 0 1px black;
      flex-shrink: 0;
      margin-right: 0;
      margin-left: 0.5em;
    }

    .grippy:after {
      content: '.... .... .... ....';
    }

  &:hover {
    background-color: ${({ theme }) => theme.inputBackground};
    border-color: ${({ theme }) => theme.secondaryBackground};
  }
`
const ALPHA_OPTS = [
  <option key='A-Z' value='1'>A-Z</option>,
  <option key='Z-A' value='-1'>Z-A</option>
]

const DATE_OPTS = [
  <option key='new' value='-1'>New</option>,
  <option key='old'>Old</option>
]

const options = [
  { label: 'Title', name: 'title', value: true, getFn: (idx, song) => `${song.title}\t` },
  { label: 'Artist', name: 'artist', value: true, getFn: (idx, song) => `${song.artist}\t` },
  { label: 'Key', name: 'key', value: false, getFn: (idx, song) => `${song.key ? song.key : '...'}\t` },
  { label: 'Tempo', name: 'tempo', value: false, getFn: (idx, song) => `${song.tempo ? song.tempo : '...'}\t` },
  { label: 'Duration', name: 'duration', value: false, getFn: (idx, song) => `${song.duration ? song.duration : '...'}\t` },
  { label: 'Last Rehearsed', name: 'last_rehearsed', value: true, getFn: (idx, song) => `${song.lastRehearsed ? timeDifference(song.lastRehearsed) : '...'}\t` }
]

const getInitStateOptions = (optArr) => {
  const columns = {}
  _each(optArr, opt => {
    columns[opt.name] = opt.value
  })
  return columns
}

const IN_LIST_ID = 'InListId'
const OUT_LIST_ID = 'OutListId'
const SETSONGS = 'setlistSongs'
const AVAILABLE = 'availableSongs'

const onlySongIds = songList => {
  return _map(songList, song => ({ _id: song._id }))
}

const filterInFromAvailable = (availableSongs, inSongs) => {
  const filtered = []
  _each(availableSongs, available => {
    let found = false
    _each(inSongs, inSong => {
      if (available._id === inSong._id) {
        found = true
      }
    })
    if (!found) {
      filtered.push(available)
    }
  })
  return filtered
}

const EditSongItem = ({ stateColumns, song, idx, hideIdx }) => (
  <>
    {!hideIdx && <span className='number'>{idx + 1}.</span>}
    {_map(options, (column, idx) => {
      if (stateColumns[column.name]) {
        return (<ListItemProp key={`setlist-column-select-${idx}`}>{column.getFn(idx, song)}</ListItemProp>)
      }
    })}
  </>
)

let saveDebounce

const EditSetList = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setlistid } = useParams()

  const [columns, setColumns] = useState(getInitStateOptions(options))
  const [setlist, setSetlist] = useState({})
  const [errors, setErrors] = useState({})
  const [isSaved, setIsSaved] = useState(true)
  const [availableSongs, setAvailableSongs] = useState([])
  const [setlistSongs, setSetlistSongs] = useState([])
  const [saveTrigger, setSaveTrigger] = useState(false)

  const isEdit = location.pathname.endsWith('edit')
  const { songs, modifyList, tags, sortBy, sortOrder, filter } = useContext(SongListContext)

  useEffect(() => {
    setAvailableSongs(filterInFromAvailable(songs, setlist.songs || []))
  }, [songs, setlist])

  useEffect(() => {
    setSetlistSongs(setlist.songs)
  }, [setlist])

  useEffect(() => {
    pageView(PAGES.SETLIST_EDIT)
    const fetchSetlist = async () => {
      if (setlistid) {
        await axios
          .get(`/api/setlists/${setlistid}`)
          .then(response => {
            if (!response.data.errmsg) {
              setSetlist({
                ...response.data
              })
            }
          })
      }
    }
    fetchSetlist()
  }, [setSetlist])

  const toggle = useCallback((name) => {
    setColumns({
      ...columns,
      [name]: !columns[name]
    })
  }, [columns, setColumns])

  const handleChange = useCallback((event) => {
    setSetlist({
      ...setlist,
      [event.target.name]: event.target.value
    })
    handleSubmit()
  }, [setlist])

  /**
   * Performs the actual save of the setlist
   * resets saved to true when complete
   */
  useEffect(() => {
    if (saveTrigger === false) {
      return
    }
    // TODO - validate!
    const url = isEdit ? `/api/setlists/${setlist._id}` : '/api/setlists'
    const method = isEdit ? 'PUT' : 'POST'
    axios({
      url,
      method,
      data: {
        title: setlist.title,
        songs: onlySongIds(setlistSongs)
      }
    }).then(response => {
      if (!response.data.errmsg) {
        // only redirect if we're creating the set, not updating
        if (!setlist._id) {
          navigate(`/setlists/${response.data._id}`)
        }
        setErrors({})
        setIsSaved(true)
      } else {
        console.log('duplicate')
      }
    }).catch(err => {
      if (err.response.status === 400) {
        // field validations
        setErrors(err.response.data)
      } else {
        // something went wrong, generic error
      }
    })
  }, [saveTrigger])

  const handleSubmit = useCallback((event) => {
    setIsSaved(false)
    if (event) {
      event.preventDefault()
    }
    clearTimeout(saveDebounce)
    saveDebounce = setTimeout(() => {
      setSaveTrigger(Date.now())
    }, 2000)
  }, [])

  const moveBetweenLists = useCallback(({ source, dest, drop }) => {
    const fromList = source === AVAILABLE ? availableSongs : setlistSongs
    const move = fromList.splice(drop.source.index, 1)
    const toList = dest === AVAILABLE ? availableSongs : setlistSongs
    toList.splice(drop.destination.index, 0, move[0])

    if (source === AVAILABLE) {
      setAvailableSongs(fromList)
      setSetlistSongs(toList)
    } else {
      setAvailableSongs(toList)
      setSetlistSongs(fromList)
    }

    handleSubmit()
  }, [setSetlistSongs, setAvailableSongs, setlistSongs, availableSongs])

  const onDragEnd = useCallback((drop) => {
    if (drop.reason === 'DROP' && drop.destination) {
      switch (true) {
        // MOVE from OUT to IN
        case drop.source.droppableId === OUT_LIST_ID && drop.destination.droppableId === IN_LIST_ID:
          moveBetweenLists({ source: AVAILABLE, dest: SETSONGS, drop })
          break

        // MOVE from OUT to OUT
        case drop.source.droppableId === OUT_LIST_ID && drop.destination.droppableId === OUT_LIST_ID:
          // do nothing...user can't reorder the available songs
          break

        // MOVE from IN to IN
        case drop.source.droppableId === IN_LIST_ID && drop.destination.droppableId === IN_LIST_ID:
          const current2 = setlistSongs // eslint-disable-line
          const move2 = current2.splice(drop.source.index, 1) // eslint-disable-line
          current2.splice(drop.destination.index, 0, move2[0])
          setSetlistSongs(current2)
          handleSubmit()
          break

        // MOVE from IN to OUT
        case drop.source.droppableId === IN_LIST_ID && drop.destination.droppableId === OUT_LIST_ID:
          moveBetweenLists({ source: SETSONGS, dest: AVAILABLE, drop })
          break

        default:
      }
    }
    if (drop.reason === 'DROP' && drop.source && !drop.destination) {
      // this is a drag out of the set but NOT to the available list
      const fromList = setlistSongs
      const move = fromList.splice(drop.source.index, 1)
      const toList = availableSongs
      toList.push(move[0])
      setSetlistSongs(fromList)
      setAvailableSongs(toList)
      handleSubmit()
    }
  }, [setlist, setSetlist, setlistSongs, availableSongs])

  return (
    <div className='EditSetlistForm'>
      <h1>Set List</h1>
      <TopContainer>
        <TopLeft>
          <CustomInputGroup>
            <label htmlFor='title'>Title: </label>
            <input
              type='text'
              name='title'
              value={setlist.title}
              onChange={handleChange}
            />
            {errors.title && <FormError>{errors.title}</FormError>}
          </CustomInputGroup>
          {!isEdit && (
            <PrimaryButton
              onClick={handleSubmit}
            >Save Setlist
            </PrimaryButton>
          )}
        </TopLeft>
        <TopRight>
          <InputGroup>
            <Duration songs={setlistSongs} />
            <SearchGroup>
              <SearchTags noCreate tags={tags} filter={filter} changeFn={modifyList} />
              <div>
                <label>Sort By</label>
                <ComboSelect>
                  <Select name='sortBy' defaultValue={sortBy} onChange={(e) => modifyList('sortBy', e.target.value)}>
                    <option key='sortTitle' value='sortTitle'>Title</option>
                    <option key='sortArtist' value='sortArtist'>Artist</option>
                    <option key='lastRehearsed' value='lastRehearsed'>Last Rehearsal</option>
                    <option key='createdAt' value='createdAt'>Created At</option>
                  </Select>
                  <Select style={{ width: '45%' }} name='sortOrder' defaultValue={sortOrder} onChange={(e) => modifyList('sortOrder', e.target.value)}>
                    {(sortBy === 'sortTitle' || sortBy === 'sortArtist') ? _map(ALPHA_OPTS) : _map(DATE_OPTS)}
                  </Select>
                </ComboSelect>
              </div>
            </SearchGroup>
          </InputGroup>
        </TopRight>
      </TopContainer>
      <OptionsContainer>
        {_map(options, (opt, idx) => (
          <CheckLabel key={`check-label-${idx}`}>
            <label htmlFor={opt.name}>
              <input type='checkbox' onChange={() => toggle(opt.name)} name={opt.name} checked={columns[opt.name]} /><span>{opt.label}</span>
            </label>
          </CheckLabel>
        ))}
      </OptionsContainer>
      <ListsContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <InListContainer>
            <Droppable droppableId={IN_LIST_ID}>
              {(provided, snapshot) => (
                <div className='target' ref={provided.innerRef}>
                  {_map(setlistSongs, (x, i) => (
                    <Draggable key={x._id} draggableId={x._id} index={i}>
                      {(provided, snapshot) => (
                        <DraggableWrap>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <StyledDraggableItem ref={i} key={`song_${x._id}`}><EditSongItem song={x} stateColumns={columns} idx={i} /></StyledDraggableItem>
                          </div>
                          {provided.placeholder}
                        </DraggableWrap>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            {isEdit && (
              <PrimaryButton
                disabled={isSaved}
                onClick={handleSubmit}
              >{isSaved ? 'All changes saved' : 'Save set list'}
              </PrimaryButton>
            )}
          </InListContainer>
          <AvailableListContainer>
            <Droppable droppableId={OUT_LIST_ID}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  {_map(availableSongs, (x, i) => (
                    <Draggable key={x._id} draggableId={x._id} index={i}>
                      {(provided, snapshot) => (
                        <DraggableWrap>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <StyledDraggableItem ref={i} key={`song_${x._id}`}><EditSongItem song={x} stateColumns={columns} idx={i} hideIdx /></StyledDraggableItem>
                          </div>
                          {provided.placeholder}
                        </DraggableWrap>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </AvailableListContainer>
          <div style={{ clear: 'both' }} />
        </DragDropContext>
      </ListsContainer>
    </div>
  )
}

export default EditSetList
