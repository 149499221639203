import React, { useCallback } from 'react'
import styled from 'styled-components'
import Autosuggest from 'react-autosuggest'

import _difference from 'lodash.difference'
import _each from 'lodash.foreach'
import _filter from 'lodash.filter'
import _includes from 'lodash.includes'
import { PrimaryButton } from '../../styled/button'
import InputGroup from '../../styled/input-group'
import { StyledTagsInput, Suggestion } from '../../styled/tags'

const WideInputGroup = styled(InputGroup)`
  .react-tagsinput-input {
    width: 100%;
  }
`

const CommonTags = ({ placeholder, label, tags, suggestions, canEdit, handleAddition, handleDelete, noCreate, handleInput = a => a, filter = '' }) => {
  const handleChange = useCallback((changeTags) => {
    const adds = _difference(changeTags, tags)
    const removes = _difference(tags, changeTags)

    _each(adds, add => {
      handleAddition(add)
    })
    _each(removes, remove => {
      handleDelete(remove)
    })
  })

  const autosuggestRenderInput = useCallback(({ addTag, ...props }) => {
    const handleOnChange = (e, { newValue, method }) => {
      if (method === 'enter') {
        e.preventDefault()
      } else {
        props.onChange(e)
      }
    }

    let handleKeyDown = k => k

    if (noCreate) {
      handleKeyDown = (e) => {
        if (e.which === 13 || e.keyCode === 13) {
          e.preventDefault()
          e.stopPropagation()
        }
      }
    }

    let inputValue = props.inputValue

    if (props.value !== undefined && typeof props.value === 'string') {
      inputValue = (props.value && props.value.trim().toLowerCase()) || ''
    }
    const filteredSuggestions = _filter(suggestions, sugg => (sugg.indexOf(inputValue) > -1 && !_includes(tags, sugg)))

    const customRenderInput = (props) => {
      const { onChange, value, addTag, inputValue, ...other } = props
      const val = value === '' ? value : value || inputValue
      if (label) {
        return (
          <WideInputGroup>
            <label>{label}</label>
            <input type='text' onChange={(e) => { handleInput(e.target.value); onChange(e) }} value={val} {...other} />{value && value.length > 0 && !noCreate && <PrimaryButton onClick={() => handleAddition(value)}>Add Tag</PrimaryButton>}
          </WideInputGroup>
        )
      } else {
        return (
          <WideInputGroup>
            <input type='text' onChange={(e) => { handleInput(e.target.value); onChange(e) }} value={val} {...other} />{value && value.length > 0 && !noCreate && <PrimaryButton onClick={() => handleAddition(value)}>Add Tag</PrimaryButton>}
          </WideInputGroup>
        )
      }
    }

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={filteredSuggestions}
        shouldRenderSuggestions={(value) => value && value.length > 2}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={(suggestion) => <Suggestion>{suggestion}</Suggestion>}
        renderInputComponent={canEdit ? customRenderInput : () => <span />}
        inputProps={{ ...props, inputValue: props.inputValue, placeholder, value: props.value ? props.value.toString() : '', onChange: handleOnChange, onKeyDown: handleKeyDown }}
        onSuggestionSelected={(e, { suggestion }) => {
          handleAddition(suggestion)
        }}
        onSuggestionsClearRequested={() => { }}
        onSuggestionsFetchRequested={() => { }}
      />
    )
  }, [suggestions, tags])

  if (!tags) {
    return <div>loading</div>
  }

  return <StyledTagsInput className='react-tagsinput' removeKeys={[]} inputValue={filter} value={tags} renderInput={autosuggestRenderInput} onChange={handleChange} onInput={handleInput} />
}

export default CommonTags
