import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import _map from 'lodash.map'
import SetlistNavContainer from '../../styled/setlist-nav-container'
import MetaSelectContainer from '../../styled/meta-select-container'

import { event, CATEGORIES, ACTIONS } from '../../utils/analytics-tracker'

const JumpSelect = styled.select`
  max-width: 200px;
`

export const navClick = () => {
  event(CATEGORIES.SETLIST, ACTIONS.NAVIGATE_SETLIST)
}

const SetlistNavigation = ({ setlist, prevSong, nextSong }) => {
  const navigate = useNavigate()

  const selectJumpToSong = (e) => {
    navigate(`/setlists/${setlist._id}/song/${e.target.value}`)
  }
  return (
    <>
      <hr />
      <h2>Set List: {setlist.title}</h2>
      <SetlistNavContainer>
        {prevSong ? <Link onClick={navClick} to={`/setlists/${setlist._id}/song/${prevSong._id}`}>❮ {prevSong.title}</Link> : <div />}
        <MetaSelectContainer>
          <label>Jump to:</label>
          <JumpSelect id='jumpToSetSong' onChange={selectJumpToSong}>
            {_map(setlist.songs, song => {
              return (
                <option key={`jump.${song._id}`} value={song._id}>{song.title} {song.artist}</option>
              )
            })}
          </JumpSelect>
        </MetaSelectContainer>
        {nextSong ? <Link onClick={navClick} to={`/setlists/${setlist._id}/song/${nextSong._id}`}>{nextSong.title} ❯</Link> : <div />}
      </SetlistNavContainer>
    </>
  )
}

export default SetlistNavigation
