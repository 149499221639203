import React from 'react'
import styled from 'styled-components'

import InputGroup from '../../styled/input-group'

const CheckLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  .label {
    padding-right: 15px;
    white-space: nowrap;
  }
  .input {
    vertical-align: middle;
    margin-right: 5px;
    width: 25px;
  }
`
export default ({ editInMonospace, updateFn }) => {
  return (
    <InputGroup style={{ width: '100%', marginBottom: '0rem' }}>
      <CheckLabel>
        <input
          className='input'
          type='checkbox'
          name='metronomeAutoStart'
          checked={editInMonospace}
          onChange={() => updateFn(!editInMonospace)}
        />
        <div className='label'>Use monospace font (useful for tablature)</div>
      </CheckLabel>
    </InputGroup>
  )
}
