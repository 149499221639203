import styled from 'styled-components'

export default styled.div`
  width: 100%;

  a {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed ${({ theme }) => theme.borderColor};
    margin-bottom: 1em;
    font-size: 1.2em;
    position: relative;
  }

  div {
    background: ${({ theme }) => theme.background};
    position: relative;
    bottom: -2px;
    text-align: left;

    &:first-child {
      padding-right: 0.3em;
    }

    &:last-child {
      padding-left: 0.3em;
      font-size: 0.7em;
      bottom: -9px;
      text-align: right;
    }
  }
`
