import React, { useEffect, useState } from 'react'
import { axios } from '../../context/auth'
import { Link } from 'react-router-dom'
import SongItem from '../../styled/song-item'

import { pageView, PAGES } from '../../utils/analytics-tracker'

// const getRehearsal = (rehearsals, group) => {
//   return _find(rehearsals, rehearsal => rehearsal.group === group._id)
// }

const GroupsList = () => {
  const [groups, setGroups] = useState([])

  useEffect(() => {
    const loadGroups = async () => {
      pageView(PAGES.GROUP_LIST_VIEW)
      const response = await axios.get('/api/groups')
      setGroups(response.data)
    }
    loadGroups()
  }, ['-'])
  // axios.get('/api/rehearsals').then(response => {
  //   if (response.data) {
  //     this.setState({
  //       rehearsals: response.data // this also adds paging data to the state
  //     })
  //   }
  // })

  // async start (groupId) {
  //   // console.log('starting!')
  //   const newRehearsalResponse = await axios.post('/api/rehearsals', { groupId })
  //   // console.log('newRehearsal', newRehearsalResponse.data)
  //   // need to set this in the rehearsal context
  //   this.props.runRehearsal(newRehearsalResponse.data)
  //   // then redirect home
  //   this.setState({
  //     redirectTo: '/'
  //   })
  // }

  // join (rehearsal) {
  //   this.setState({
  //     redirectTo: `/rehearsal/${rehearsal.publicId}`
  //   })
  // }

  return (
    <>
      <div className='groupListHeader'>
        <h2>My Groups</h2>
      </div>
      {groups.map(group => (
        <SongItem key={group._id}>
          <Link to={`/groups/${group._id}`}><div>{group.title}</div><div>{group.users.length} members</div></Link>
          {/* {getRehearsal(rehearsals, group)
              ? <button onClick={() => this.join(getRehearsal(rehearsals, group))}>Join Rehearsal</button>
              : <button onClick={() => this.start(group._id)}>Start Rehearsing</button> } */}
        </SongItem>
      )
      )}
      <Link to='/new-group'>New Group</Link>
    </>
  )
}

export default GroupsList
