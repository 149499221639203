import Soundfont from 'soundfont-player'
import _each from 'lodash.foreach'
import _find from 'lodash.find'
import _findIndex from 'lodash.findindex'
import _includes from 'lodash.includes'

import { getChordParts } from './transpose-chord'

const halfSteps = [
  ['C'],
  ['C#', 'Db'],
  ['D'],
  ['Eb', 'D#'],
  ['E', 'Fb'],
  ['F', 'E#'],
  ['F#', 'Gb'],
  ['G'],
  ['G#', 'Ab'],
  ['A'],
  ['Bb', 'A#'],
  ['B']
]

// const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const formulas = [ // formula is 1/2 steps from root
  { label: 'major', markers: [''], progression: [4, 7] },
  { label: 'minor', markers: ['m'], progression: [3, 7] },
  { label: '7th', markers: ['7'], progression: [4, 7, 10] },
  { label: 'minor 7th', markers: ['m7'], progression: [3, 7, 10] },
  { label: 'major 7th', markers: ['maj7'], progression: [4, 7, 11] },
  { label: 'minor 7th flat 5th', markers: ['m7b5', 'ø'], progression: [3, 6, 10] },
  { label: 'suspended 4th', markers: ['sus4', 'sus'], progression: [5, 7] },
  { label: 'suspended 2nd', markers: ['sus2'], progression: [2, 7] },
  { label: 'diminished', markers: ['dim', 'o'], progression: [3, 6] },
  { label: 'augmented', markers: ['aug', '+'], progression: [4, 8] },
  { label: '6th', markers: ['6'], progression: [4, 7, 9] },
  { label: 'minor 6th', markers: ['m6'], progression: [3, 7, 9] },
  { label: '6th Add 9th', markers: ['6add9'], progression: [4, 7, 9, 14] },
  { label: '9th', markers: ['9'], progression: [4, 7, 10, 14] },
  { label: 'Add 9th', markers: ['6add9'], progression: [4, 7, 9, 14] },
  { label: 'minor 9th', markers: ['m9'], progression: [3, 7, 10, 14] },
  { label: 'major 9th', markers: ['maj9', 'add9'], progression: [4, 7, 11, 14] },
  { label: 'power major', markers: ['5'], progression: [4, 7] } // same as major
]

export const findFormula = (chordMod) => {
  return _find(formulas, formula => {
    return _includes(formula.markers, chordMod)
  })
}

const getFormulaNotes = (rootNote, progression, startOctave) => {
  const notes = []
  const noteIndex = _findIndex(halfSteps, idxArray => _includes(idxArray, rootNote))
  let octave = startOctave

  notes[0] = `${rootNote}${startOctave}`

  for (let x = 0; x < progression.length; x++) {
    let currentNoteIndex = noteIndex + progression[x]
    if (currentNoteIndex > 11) {
      const diff = Math.floor(currentNoteIndex / 12)
      octave = diff + startOctave
      currentNoteIndex %= 12
    }
    notes[x + 1] = `${halfSteps[currentNoteIndex][0]}${octave}`
  }
  return notes
}

export const getNotesToPlay = (chord, startOctave = 4) => {
  try {
    const hasBaseNote = chord.indexOf('/') > -1

    const [mainChord, baseChord] = hasBaseNote ? chord.split('/') : [chord]
    const [baseNote] = hasBaseNote ? getChordParts(baseChord) : [undefined]

    const [mainRoot, mainMod] = getChordParts(mainChord)
    const mainFormula = findFormula(mainMod)
    if (!mainFormula) {
      return []
    }

    const notesToPlay = getFormulaNotes(mainRoot, mainFormula.progression, startOctave)

    if (baseNote) {
      notesToPlay[0] = `${baseNote}${startOctave - 1}`
    }
    return notesToPlay
  } catch (e) {
    console.log(e)
    return []
  }
}

// const AudioContext = window.AudioContext || window.webkitAudioContext
// const ac = new AudioContext()

let ac, vca

export default (chord, instrumentName = 'acoustic_guitar_steel', startOctave = 4) => {
  if (!ac) {
    const AudioContext = window.AudioContext || window.webkitAudioContext
    ac = new AudioContext()
    vca = ac.createGain()
    vca.gain.value = 1
    vca.connect(ac.destination)
    ac.resume()
  }

  const notes = getNotesToPlay(chord, startOctave)
  Soundfont.instrument(ac, instrumentName, { destination: vca }).then(function (instr) {
    // let delay = 0
    _each(notes, note => {
      instr.play(note)
    })
  })
}
