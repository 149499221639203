import React, { useCallback, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import _each from 'lodash.foreach'
import _map from 'lodash.map'
import { useFormik } from 'formik'

import { PrimaryButton } from '../../styled/button'
import OptionsContainer from '../../styled/options-container'
import getSongFirstChord from '../../utils/song-first-chord'
import durationFromSongs from '../../utils/duration-from-songs'

import styled from 'styled-components'

const CheckLabel = styled.div`
  display: inline-block;
  label {
    display: inline-block;
    padding-right: 15px;
    white-space: nowrap;
  }
  input {
    vertical-align: middle;
    margin-right: 5px;
  }
  label span {
    vertical-align: middle;
  }
`

const Options = styled.div`
  display: inline-block;
  margin-left: 15px;
`

export const options = [
  { label: 'No.', name: 'number', value: true, getFn: (idx) => `${idx}. ` },
  { label: 'Title', name: 'title', value: true, getFn: (idx, song) => `${song.title}\t` },
  { label: 'Artist', name: 'artist', value: false, getFn: (idx, song) => `${song.artist}\t` },
  { label: 'Key', name: 'key', value: false, getFn: (idx, song) => `${song.key ? song.key : '...'}\t` },
  { label: 'Tempo', name: 'tempo', value: false, getFn: (idx, song) => `${song.tempo ? song.tempo : '...'}\t` },
  { label: 'Duration', name: 'duration', value: false, getFn: (idx, song) => `${song.duration ? song.duration : '...'}\t` },
  { label: 'First Chord', name: 'firstChord', value: false, getFn: (idx, song) => `${getSongFirstChord(song)}\t` }
]

const getInitStateOptions = (optArr) => {
  const columns = {}
  _each(optArr, opt => {
    columns[opt.name] = opt.value
  })
  return columns
}

const buildClipBoard = (setlist, columns) => {
  let txt = `${setlist.title}\n\n`
  let songIndex = 1

  _each(setlist.songs, song => {
    _each(options, (opt) => {
      if (columns[opt.name] === true) {
        txt = `${txt}${opt.getFn(songIndex, song)}`
      }
    })

    txt = `${txt}\n`
    songIndex++
  })

  const { missing, totalFormated } = durationFromSongs(setlist.songs)

  if (columns.duration) {
    txt = `${txt} Duration: ${totalFormated}`
    if (missing !== 0) {
      txt = `${txt}, ${missing} missing duration`
    }
  }

  return txt
}

const CopySetList = ({ setlist }) => {
  const [copied, setCopied] = useState(false)

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: getInitStateOptions(options)
  })

  const onCopy = useCallback(() => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  })

  const copyText = buildClipBoard(setlist, values)
  return (
    <>
      <OptionsContainer>
        <CopyToClipboard text={copyText} onCopy={onCopy}>
          <PrimaryButton>Copy to Clipboard</PrimaryButton>
        </CopyToClipboard>
        <Options>
          {_map(options, (opt, idx) => (
            <CheckLabel key={`copy-setlist-option=${idx}`} onClick={() => setFieldValue(opt.name, !values[opt.name])}>
              <label htmlFor={opt.name}>
                <input type='checkbox' name={opt.name} onChange={handleChange} checked={values[opt.name]} /><span>{opt.label}</span>
              </label>
            </CheckLabel>
          ))}
        </Options>
        {copied && <span>Copied!</span>}
      </OptionsContainer>
    </>
  )
}

export default CopySetList
