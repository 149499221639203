import _some from 'lodash.some'

const hasAny = (objectPerms, lookingForPerms) => {
  return _some(objectPerms, objPerm => {
    return _some(lookingForPerms, lookingFor => lookingFor === objPerm)
  })
}

export const isOwner = (obj) => {
  return hasAny(obj.permissions, ['OWNER'])
}

export const canEdit = (obj) => {
  return hasAny(obj.permissions, ['OWNER', 'EDITOR'])
}

export const canView = (obj) => {
  return hasAny(obj.permissions, ['OWNER', 'EDITOR', 'VIEWER'])
}

export const isMember = (obj) => {
  return hasAny(obj.permissions, ['MEMBER'])
}
