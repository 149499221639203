import React from 'react'
import durationFromSongs from '../../utils/duration-from-songs'

import { DurationContainer, TotalTime } from '../../styled/duration'

export default ({ songs }) => {
  if (!songs) {
    return null
  }

  const { missing, totalFormated } = durationFromSongs(songs)

  if (missing === songs.length) {
    return null
  }

  return (
    <DurationContainer>
      <TotalTime>{totalFormated}</TotalTime>
      {missing !== 0 && <div>{missing} songs missing duration.</div>}
    </DurationContainer>
  )
}
