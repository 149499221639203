import React, { useCallback, useEffect, useState } from 'react'

import _each from 'lodash.foreach'
import _find from 'lodash.find'
import _map from 'lodash.map'
import _pick from 'lodash.pick'
import { Link } from 'react-router-dom'
import { axios } from '../../context/auth'
import styled from 'styled-components'

import { canEdit } from '../../utils/auth'

import OptionsContainer from '../../styled/options-container'
import { PrimaryButton } from '../../styled/button'
import InputGroup from '../../styled/input-group'
import { event, CATEGORIES, ACTIONS } from '../../utils/analytics-tracker'

const FlexRowInputGroup = styled(InputGroup)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: .5rem;
`

const SetListItem = styled.div`
  display: inline-block;
  margin: 10px;
`

const MarginBottomButton = styled(PrimaryButton)`
  margin-bottom: 1rem;
  margin-left: .5rem;
`

/**
 * Separates sets where the song is already in it.
 *
 * @param {*} songId
 * @param {*} allSets
 */
const splitSets = (songId, allSets) => {
  const inSets = []
  const outSets = []

  _each(allSets, currentSet => {
    let isInSet = false
    _each(currentSet.songs, setSong => {
      // songs aren't populated when get the setlist list,
      // so the songs in the set are actually the song ids
      if (setSong === songId) {
        isInSet = true
      }
    })
    if (isInSet) {
      inSets.push(currentSet)
    } else {
      outSets.push(currentSet)
    }
  })
  return {
    inSets,
    outSets
  }
}

let setsSource

const SongSets = ({ songid }) => {
  const [state, setState] = useState({
    updated: false,
    inSets: [],
    outSets: [],
    selectedSet: '',
    addButtonEnabled: false
  })

  const refreshSongSets = useCallback(async () => {
    try {
      // setsSource = axios.CancelToken.source()setsSource.token
      const response = await axios.get('/api/setlists', { cancelToken: '' })
      const splitSetsData = splitSets(songid, response.data)
      setState({
        ...state,
        ...splitSetsData,
        selectedSet: splitSetsData.outSets[0] ? splitSetsData.outSets[0]._id : null,
        addButtonEnabled: splitSetsData.outSets[0] ? canEdit(splitSetsData.outSets[0]) : false
      })
    } catch (err) {
      console.log(err)
    }
  })

  useEffect(() => {
    setTimeout(() => {
      refreshSongSets()
    }, 500)

    return () => {
      if (setsSource) {
        setsSource.cancel('setlists fetch cancelled, song-sets unmounted')
      }
    }
  }, ['-'])

  const addSongToSet = useCallback(async () => {
    if (state.selectedSet === null) {
      // just in case...the button should be disabled in this case
      return
    }
    // get the set again
    try {
      const theSet = await axios.get(`/api/setlists/${state.selectedSet}?noview=true`)
      // add the song
      theSet.data.songs.push({ _id: songid })

      const newSet = _pick(theSet.data, ['songs', 'title'])

      // save it
      await axios.put(`/api/setlists/${state.selectedSet}`, newSet)

      event(CATEGORIES.SONG, ACTIONS.QUICK_ADD_SETLIST)
    } catch (err) {
      console.log(err)
    }
    // reload sets
    refreshSongSets()
  })

  const changeSetList = useCallback((event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
      addButtonEnabled: canEdit(_find(state.outSets, outSet => outSet._id === event.target.value))
    })
  })

  return (
    <OptionsContainer>
      <h4>In Set Lists</h4>
      <div>
        {state.inSets.length === 0
          ? <SetListItem>(None)</SetListItem>
          : _map(state.inSets, inSet =>
            <SetListItem key={inSet._id}>
              <Link to={`/setlists/${inSet._id}`}>{inSet.title}</Link>
            </SetListItem>
          )}
      </div>

      <FlexRowInputGroup>
        <select name='selectedSet' onChange={changeSetList}>
          {_map(state.outSets, outSet => {
            return <option key={outSet._id} value={outSet._id}>{outSet.title}</option>
          })}
        </select>
        <MarginBottomButton onClick={addSongToSet} disabled={!state.addButtonEnabled}>Quick Add</MarginBottomButton>
      </FlexRowInputGroup>
    </OptionsContainer>
  )
}

export default SongSets
