import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import _map from 'lodash.map'

import { axios } from '../../context/auth'
import SongExportView from '../songs/export-view'
import { pageView, PAGES } from '../../utils/analytics-tracker'

const ExportSetList = ({ setlistid }) => {
  const { setlistid: matchSetlistid } = useParams()

  const useSetlistId = setlistid || matchSetlistid
  const [setlist, setSetlist] = useState()

  const refreshSetList = useCallback(async () => {
    try {
      const response = await axios.get(`/api/setlists/${useSetlistId}`)
      setSetlist(response.data)
    } catch (err) {
      console.log(err)
    }
  })

  useEffect(() => {
    pageView(PAGES.SETLIST_EXPORT)
    refreshSetList()
  }, ['-'])

  if (!setlist) {
    return <div>loading</div>
  }
  return (
    <>
      <div className='ExportSetList'>
        <h1>{setlist.title}</h1>

        <br /><br />

        {_map(setlist.songs, setListSong => {
          return <SongExportView key={setListSong._id} song={setListSong} />
        })}
      </div>
    </>
  )
}

export default ExportSetList
