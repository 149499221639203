import React, { createContext, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _includes from 'lodash.includes'

import { event, CATEGORIES, ACTIONS } from '../utils/analytics-tracker'
import cachingClient from '../utils/caching-client'

const SongListContext = createContext()

const validParams = ['count', 'page', 'sortOrder', 'sortBy', 'filter', 'tags']

let debounceSearch
// let songsSource

const SongListProvider = ({ children, user }) => {
  const [loading, setLoading] = useState(false)
  const [songList, setSongList] = useState([])
  const [hasMore, setHasMore] = useState(false)

  const [filter, setFilter] = useState('')
  const [params, setParams] = useState({
    count: 25,
    page: 1,
    sortOrder: 1,
    sortBy: 'sortTitle',
    tags: ''
  })

  const _modifyList = useCallback((parameter, value) => {
    // console.log('modifyList', parameter, value)
    if (!_includes(validParams, parameter)) {
      console.error('attempted to update song list with invalid parameter')
      return false
    }

    clearTimeout(debounceSearch)
    if (parameter === 'filter') {
      setFilter(value)
      // reset page when changing filter

      debounceSearch = setTimeout(() => {
        event(CATEGORIES.SONG, ACTIONS.SEARCH)
        const newParams = {
          ...params,
          page: 1
        }
        setParams(newParams)
        refreshList(newParams, value)
      }, 750)
    } else {
      let alsoResetPage = {}
      if (parameter === 'count') {
        alsoResetPage = { page: 1 }
        setHasMore(true)
      }

      const newParams = {
        ...params,
        [parameter]: value,
        ...alsoResetPage
      }
      setParams(newParams)

      let newFilter = filter
      if (parameter === 'tags') {
        if (params.tags.split(',').length < value.split(',').length) {
          setFilter()
          newFilter = undefined
        }
      }

      refreshList(newParams, newFilter)
    }
  })

  useEffect(() => {
    if (user) {
      refreshList(params, filter)
    }
    return () => {
      // if (songsSource) {
      //   songsSource.cancel('Song list request canceled due to unmounting song list context')
      // }
    }
  }, ['-', user])

  const refreshList = useCallback((params, filter) => {
    const defaults = { count: 25, page: 1, sortOrder: 1, sortBy: 'sortTitle', filter: '', tags: '' }

    const reqData = {
      ...defaults,
      ...params,
      filter
    }

    setLoading({
      loading: true
    })

    // if (songsSource) {
    //   songsSource.cancel()
    // }

    // songsSource = axios.CancelToken.source()
    const url = `/api/songs?count=${reqData.count}&page=${reqData.page}&sortorder=${reqData.sortOrder}&sortby=${reqData.sortBy}&filter=${reqData.filter}&tags=${reqData.tags}`
    cachingClient({
      key: 'song-list',
      id: url,
      method: 'GET',
      url
    }).then(response => {
      if (response.data && response.data.songs) {
        if (response.data.songs.length === 0) {
          // no more songs, dont replace the song list
          setSongList([])
          setHasMore(response.data.hasMore)
        } else {
          // update song list and number of pages
          setSongList(response.data.songs)
          setHasMore(response.data.hasMore)
        }
      }
      setLoading(false)
    }).catch(() => {
      setSongList([])
    })
  })

  return (
    <SongListContext.Provider
      value={{
        modifyList: _modifyList,
        songs: songList,
        count: params.count,
        page: params.page,
        sortOrder: params.sortOrder,
        sortBy: params.sortBy,
        tags: params.tags,
        filter,
        hasMore,
        loading
      }}
    >
      {children}
    </SongListContext.Provider>
  )
}

const SongListConsumer = SongListContext.Consumer

SongListProvider.propTypes = {
  children: PropTypes.element
}

export const context = SongListContext

export { SongListProvider, SongListConsumer }
