import React, { useContext } from 'react'
import SongLine from '../../styled/song-line'
import SongBody, { ScrollableSongBody, ChordButton } from '../../styled/song-content'
import transpose from '../../utils/transpose-chord'
import playChord from '../../utils/chord-player'

import { context as AuthContext } from '../../context/auth'

const chordRegex = /\{\S+?\}/gi

const MIN_SPACE = 5 // minimum chars between chords for above style!

export default ({ content, transposeSteps = 0, songTheme, editMode }) => {
  const { user: { settings: { playbackInstrument, playbackOctave } } } = useContext(AuthContext)
  const songLines = content.split('\n')

  const chordReplace = (str) => {
    try {
      return str.replace(/\{|\}/g, '')
    } catch (e) {
      console.log('error in chordReplace: ', e)
      return str
    }
  }

  const songMarkup = songLines.map((line, lineIndex) => {
    const containsTablature = line.split('|').length - 1 >= 2
    const assembled = []
    const right = []
    const parts = line.split(chordRegex)
    const matches = line.match(chordRegex)
    let left = 0
    let previous = ''
    let previousMatch = ''

    for (let i = 0; i < parts.length; i++) {
      previous = parts[i]
      assembled.push(parts[i])
      if (matches !== null && matches[i]) {
        const chord = transpose(chordReplace(matches[i]), transposeSteps)
        if (previous.length < MIN_SPACE && i !== 0) {
          left += ((previousMatch.length - 2) * 10) + 20
        } else {
          left = 0
        }
        if (songTheme === 'float-right') {
          right.push(
            <ChordButton songTheme='inline' left={left} key={`song-pt-${i}`} className='anchor' onClick={() => playChord(chord, playbackInstrument, playbackOctave)}>
              <span key={`song-chrd-${i}`} className='chord'>{chord}</span>
            </ChordButton>
          )
        } else {
          assembled.push(
            <ChordButton songTheme={songTheme} left={left} key={`song-pt-${i}`} className='anchor' onClick={() => playChord(chord, playbackInstrument, playbackOctave)}>
              <span key={`song-chrd-${i}`} className='chord'>{chord}</span>
            </ChordButton>
          )
        }
        previousMatch = matches[i]
      }
    }
    assembled.push(' ')
    right.push(' ')
    if (songTheme === 'float-right') {
      return <SongLine monospace={containsTablature} key={`line-${lineIndex}`}><div>{assembled}</div><div style={{ float: 'right', display: 'block' }}>{right}</div></SongLine>
    } else {
      return <SongLine monospace={containsTablature} key={`line-${lineIndex}`}>{assembled}</SongLine>
    }
  })

  if (editMode) {
    return <ScrollableSongBody songTheme={songTheme}>{songMarkup}</ScrollableSongBody>
  }
  return <SongBody songTheme={songTheme}>{songMarkup}</SongBody>
}
