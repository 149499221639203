export const getQueryParam = (param, url) => {
  try {
    const rx = new RegExp('[?&]' + param + '=([^&]+).*$')
    const returnVal = url.match(rx)
    return returnVal === null ? undefined : returnVal[1]
  } catch (e) {
    return undefined
  }
}

export default getQueryParam
