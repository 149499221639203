import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { axios } from '../../context/auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import _find from 'lodash.find'
import _map from 'lodash.map'

import FormError, { FormInfo } from '../../styled/form-error'
import InputGroup, { SmallInputGroup } from '../../styled/input-group'
import { PrimaryButton } from '../../styled/button'

import SongContent from './content'
import ContextMenu from './context-menu'
import AutoChord from './auto-chord'
import EditMonospace from './edit-monospace'
import formatFromPaste, { looksLikeChordsAbovePaste } from './auto-paste'
import confirm from '../confirmable'
// import HelpContent from './help'
import DetectKey from './key'

import { pageView, PAGES } from '../../utils/analytics-tracker'

import { SONG_KEYS, SIGNATURES } from '../../constants'

const TextArea = styled.textarea`
  ${({ monospace }) => monospace && 'font-family: "Lucida Console", Courier, monospace;'}
`

const escape = (str) => {
  try {
    return str.replace(/\\n/g, '\\n')
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f')
  } catch (e) {
    console.log('error in song edit escape: ', e)
    return str
  }
}

const urlSpaceReplace = (str) => {
  try {
    return str.replace(/ /g, '+')
  } catch (e) {
    console.log('error in urlSpaceReplace', e)
    return str
  }
}

const getYoutubeSearchUrl = (artist, title) => {
  const searchArtist = urlSpaceReplace(artist)
  const searchTitle = urlSpaceReplace(title)
  return `https://www.youtube.com/results?search_query=${searchArtist}+${searchTitle}`
}

const PreviewContainer = styled.div`
  width: 100%;
  margin: auto;
`

const PreviewLeft = styled.div`
  width: 50%;
  float: left;
  @media (max-width: 1024px) {
    width: 100%;
  }
`

const PreviewRight = styled.div`
  margin-left: 50%;
  padding-left: 15px;
  @media (max-width: 1024px) {
    display: none;
  }
`

const EditSongSchema = Yup.object().shape({
  artist: Yup.string().min(2).max(150).required().label('Song Artist'),
  title: Yup.string().min(2).max(150).required().label('Song Title'),
  content: Yup.string().max(10000).required().label('Song Content'),
  media: Yup.string().max(300).label('Media Link').optional(),
  key: Yup.string().max(16).optional(),
  tempo: Yup.string().max(16).optional(),
  duration: Yup.string().max(16).optional(),
  signature: Yup.string().max(2).optional(),
  meta1: Yup.string().max(64).optional(),
  meta2: Yup.string().max(64).optional(),
  meta3: Yup.string().max(64).optional(),
  meta4: Yup.string().max(64).optional(),
  notes: Yup.string().max(3000).label('Song Notes').optional()
})

const EditSong = () => {
  const location = useLocation()
  const { songid } = useParams()
  const navigate = useNavigate()
  const isEdit = location.pathname.endsWith('edit')
  const [editInMonospace, setEditInMonospace] = useState(false)
  const [contextEvent, setContextEvent] = useState()
  // const [, setContext] = useState()

  const { errors, values, handleBlur, handleChange, setValues, handleSubmit, setFieldValue, setErrors, touched } = useFormik({
    initialValues: {
      title: '',
      artist: '',
      content: '',
      media: '',
      key: '',
      duration: '',
      tempo: '',
      transposed: 0,
      signature: '',
      meta1: '',
      meta2: '',
      meta3: '',
      meta4: '',
      _id: undefined
    },
    validationSchema: EditSongSchema,
    onSubmit: (formValues) => save(formValues)
  })

  const save = useCallback(async (values) => {
    // TODO - validate!
    const url = isEdit ? `/api/songs/${values._id}` : '/api/songs'
    const method = isEdit ? 'PUT' : 'POST'

    try {
      const response = await axios({
        url,
        method,
        data: {
          title: escape(values.title),
          artist: values.artist,
          content: values.content,
          media: values.media,
          key: values.key,
          duration: values.duration,
          tempo: values.tempo,
          meta1: values.meta1,
          meta2: values.meta2,
          meta3: values.meta3,
          meta4: values.meta4,
          transposed: isEdit ? values.transposed : 0,
          signature: values.signature
        }
      })
      setValues(response.data)
      navigate(`/songs/${response.data._id}`)
    } catch (err) {
      if (err.response.status === 400) {
        setErrors(err.response.data)
      } else {
        console.log('err', err.response)
      }
    }
  })

  const loadSong = useCallback(async () => {
    pageView(PAGES.SONG_EDIT)
    if (songid) {
      try {
        const response = await axios.get(`/api/songs/${songid}`)
        setValues(response.data)
      } catch (err) {
        console.log('err', err.response)
      }
    }
  }, [songid])

  useEffect(() => {
    // on mount
    document.addEventListener('contextmenu', _handleContextMenu)
    document.addEventListener('click', _handleClick)
    document.addEventListener('scroll', _handleScroll)

    loadSong()
    // on unmount
    return () => {
      document.removeEventListener('contextmenu', _handleContextMenu)
      document.removeEventListener('click', _handleClick)
      document.removeEventListener('scroll', _handleScroll)
    }
  }, ['-'])

  const _handleClick = useCallback((e) => {
    if (!_find(e.path, pathPart => pathPart.id === 'songEditContext')) {
      setContextEvent(undefined)
    }
  })

  const insertChord = useCallback((inChord) => {
    const chord = `{${inChord}} `
    const element = document.getElementById('songEditContent')

    if (element.selectionStart || element.selectionStart === '0') {
      const startPos = element.selectionStart
      element.value = `${element.value.substring(0, startPos)}${chord}${element.value.substring(startPos, element.value.length)}`
    } else {
      element.value += chord
    }

    setContextEvent(undefined)
    setFieldValue('content', element.value)
  })

  const shortcutChord = useCallback((e) => {
    const element = document.getElementById('songEditContent')
    // BracketLeft and BracketRight keycodes
    if (e.shiftKey === false || (e.keyCode !== 221 && e.keyCode !== 219)) {
      return
    }

    const start = element.selectionStart
    const end = element.selectionEnd

    if (start !== undefined && end !== undefined && end - start > 0) {
      e.preventDefault()

      const before = element.value.substring(0, start)
      const content = element.value.substring(start, end)
      const after = element.value.substring(end)

      element.value = `${before}{${content}}${after}`

      setFieldValue('content', element.value)

      element.focus()
      element.selectionStart = end + 2
      element.selectionEnd = end + 2
    }
  })

  const _handleScroll = useCallback(() => {
    setContextEvent(undefined)
  })

  const _handleContextMenu = useCallback((e) => {
    if (e.target.name === 'content') {
      e.preventDefault()
      setContextEvent(e)
    }
  })

  const pasteMagician = useCallback((e) => {
    const pasted = e.clipboardData.getData('Text')
    if (looksLikeChordsAbovePaste(pasted)) {
      confirm('Looks like you\'ve pasted content with chords over the lyrics.\n\r\n\rAttempt to merge and format them?').then(
        (result) => {
          setFieldValue('content', formatFromPaste(pasted))
        }
      )
    }
  })

  return (
    <div className='SongEdit'>
      <ContextMenu songContent={values.content} contextEvent={contextEvent} insertFn={insertChord} />
      <InputGroup>
        <label htmlFor='title'>Title: </label>
        <input
          type='text'
          name='title'
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.title && errors.title && <FormError>{errors.title}</FormError>}
        <label htmlFor='artist'>Artist: </label>
        <input
          type='text'
          name='artist'
          value={values.artist}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.artist && errors.artist && <FormError>{errors.artist}</FormError>}
        <AutoChord content={values.content} updateFn={newContent => setFieldValue('content', newContent)} />
        <EditMonospace editInMonospace={editInMonospace} updateFn={change => setEditInMonospace(change)} />
        {values.transposed !== 0 && <FormInfo>Song is transposed. You are editing in the original key.</FormInfo>}
        <DetectKey songContent={values.content} />
        <ScrollSync>
          <PreviewContainer>
            <PreviewLeft>
              <label htmlFor='content'>content: </label>
              <ScrollSyncPane>
                <TextArea
                  monospace={editInMonospace}
                  id='songEditContent'
                  type='text'
                  name='content'
                  value={values.content}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly={contextEvent ? 'readonly' : ''}
                  onKeyDown={shortcutChord}
                  onPaste={pasteMagician}
                />
                {touched.content && errors.content && <FormError>{errors.content}</FormError>}
              </ScrollSyncPane>
              <br />
            </PreviewLeft>
            <PreviewRight>
              <label>preview: </label>
              <ScrollSyncPane>
                <SongContent editMode content={values.content} />
              </ScrollSyncPane>
            </PreviewRight>
          </PreviewContainer>
        </ScrollSync>
        <div style={{ clear: 'both' }} />
        <label htmlFor='media'>Media Link: {values.media === '' && <a href={getYoutubeSearchUrl(values.artist, values.title)} target='_blank' rel='noopener noreferrer'>Search</a>}</label>
        <input
          type='text'
          name='media'
          value={values.media}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.media && errors.media && <FormError>{errors.media}</FormError>}
      </InputGroup>
      <div style={{ clear: 'both' }} />
      <SmallInputGroup>
        <div className='small'>
          <label htmlFor='meta1'>Meta 1: </label>
          <input
            type='text'
            name='meta1'
            value={values.meta1}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.meta1 && errors.meta1 && <FormError>{errors.meta1}</FormError>}
        </div>
        <div className='small'>
          <label htmlFor='meta2'>Meta 2: </label>
          <input
            type='text'
            name='meta2'
            value={values.meta2}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.meta2 && errors.meta2 && <FormError>{errors.meta2}</FormError>}
        </div>
        <div className='small'>
          <label htmlFor='meta3'>Meta 3: </label>
          <input
            type='text'
            name='meta3'
            value={values.meta3}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.meta3 && errors.meta3 && <FormError>{errors.meta3}</FormError>}
        </div>
        <div className='small'>
          <label htmlFor='meta4'>Meta 4: </label>
          <input
            type='text'
            name='meta4'
            value={values.meta4}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.meta4 && errors.meta4 && <FormError>{errors.meta4}</FormError>}
        </div>
      </SmallInputGroup>
      <div style={{ clear: 'both' }} />
      <SmallInputGroup>
        <div className='small'>
          <label htmlFor='key'>Key: </label>
          <select name='key' onChange={handleChange} value={values.key}>
            {_map(SONG_KEYS, songKey => {
              return <option key={songKey} value={songKey}>{songKey}</option>
            })}
          </select>
        </div>
        <div className='small'>
          <label htmlFor='tempo'>Tempo: </label>
          <input
            type='text'
            name='tempo'
            value={values.tempo}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.tempo && errors.tempo && <FormError>{errors.tempo}</FormError>}
        </div>
        <div className='small'>
          <label htmlFor='signature'>Signature: </label>
          <select name='signature' onChange={handleChange} value={values.signature}>
            {_map(SIGNATURES, sig => {
              return <option key={sig.value} value={sig.value}>{sig.display}</option>
            })}
          </select>
        </div>
        <div className='small'>
          <label htmlFor='duration'>Duration: (mm:ss)</label>
          <input
            type='text'
            name='duration'
            value={values.duration}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.duration && errors.duration && <FormError>{errors.duration}</FormError>}
        </div>
      </SmallInputGroup>
      <PrimaryButton type='submit' onClick={handleSubmit}>Save Song</PrimaryButton>
    </div>
  )
}

export default EditSong
