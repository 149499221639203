import React, { useState, useContext } from 'react'
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa'

import styled from 'styled-components'
import _map from 'lodash.map'

import { context as AuthContext } from '../../context/auth'
import playChord from '../../utils/chord-player'

import { SmallButton } from '../../styled/button'
import detectKey, { getSongChords, getKeyChords } from './key/detect-key'

const Menu = styled.div`
    top: ${props => props.top};
    left: ${props => props.left};
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
    width: 425px;
    padding: 10px;
    z-index: 9999;
    
    @media(max-width: 600px) {
      width: 100%;
      left: 0px;
    }

    &--option {
        padding: 6px 50px 5px 10px;
        min-width: 160px;
        cursor: default;
        font-size: 12px;
        &:hover {
            background: linear-gradient(to top, #555, #333);
            color: white;
        }
        
        &:active {
            color: #e9e9e9;
            background: linear-gradient(to top, #555, #444);
        }
        
        &__disabled {
            color: #999999;
            pointer-events: none;
        }
    }
    
    &--separator {
        width: 100%;
        height: 1px;
        background: #CCCCCC;
        margin: 0 0 0 0;
    }
`
const Builder = styled.div`
    display: flex;    
    flex-direction:row;
    justify-content: space-around;
`

const BuilderOptions = styled.div`
    display:flex;
    flex-direction:column;
`

const BuiltChord = styled.div`
    font-size: 2em;
    display:flex;
    flex-direction:column;
    text-align: center;
    width: 60px;
`

const BuilderButton = styled(SmallButton)`
    ${props => props.selected
? ''
: `
        background: white;
        color: ${props.theme.primary};
     `}
  margin-bottom: 3px;
`

const defaultChords = ['C', 'D', 'E', 'F', 'G', 'A', 'B']
const scales = [
  { name: 'M', append: '' },
  { name: 'm', append: 'm' },
  { name: '7', append: '7' },
  { name: 'm7', append: 'm7' },
  { name: 'maj7', append: 'maj7' },
  { name: 'dim', append: 'dim' }
]
const steps = [
  'b', '#'
]

export default ({ contextEvent, insertFn, songContent = '' }) => {
  if (!contextEvent) {
    return null
  }

  const [builderChord, setBuilderChord] = useState()
  const [builderScale, setBuilderScale] = useState('')
  const [builderStep, setBuilderStep] = useState('')
  const [audioEnabled, setAudioEnabled] = useState(false)

  const { user: { settings: { playbackInstrument, playbackOctave } } } = useContext(AuthContext)

  const currentSongChords = getSongChords(songContent)
  const detectedSongKey = detectKey(songContent)
  const keyChords = detectedSongKey[0] ? getKeyChords(detectedSongKey[0].key) : undefined

  const clickX = contextEvent.clientX
  const clickY = contextEvent.clientY

  const windowHeight = window.innerHeight

  const menuLeft = `${clickX + 5}px`
  const menuTop = clickY + 350 > windowHeight ? `${windowHeight - 350}px` : `${clickY + 5}px`

  return (
    <Menu id='songEditContext' left={menuLeft} top={menuTop}>
      <h5>Existing Chords</h5>
      {_map(currentSongChords, currentChord => {
        return <BuilderButton selected onClick={(e) => { e.preventDefault(); insertFn(currentChord) }}>{currentChord}</BuilderButton>
      })}
      <hr />
      <h5>Song Key Chords</h5>
      {_map(keyChords, keyChord => {
        return <BuilderButton selected onClick={(e) => { e.preventDefault(); insertFn(keyChord) }}>{keyChord}</BuilderButton>
      })}
      <hr />
      <h5>Chord Builder</h5>
      <Builder>
        <BuilderOptions>
          <div>
            {_map(defaultChords, dChord => {
              return <BuilderButton selected={builderChord === dChord} onClick={(e) => { e.preventDefault(); setBuilderChord(dChord) }}>{dChord}</BuilderButton>
            })}
          </div>
          <div>
            {_map(steps, step => {
              return <BuilderButton selected={step === builderStep} onClick={(e) => { e.preventDefault(); setBuilderStep(step === builderStep ? '' : step) }}>{step}</BuilderButton>
            })}
          </div>
          <div>
            {_map(scales, scale => {
              return <BuilderButton selected={scale.append === builderScale} onClick={(e) => { e.preventDefault(); setBuilderScale(scale.append) }}>{scale.name}</BuilderButton>
            })}
          </div>
        </BuilderOptions>
        <BuiltChord>
          {audioEnabled && <BuilderButton onClick={() => setAudioEnabled(false)}><FaVolumeUp size={16} wut={playChord(`${builderChord}${builderStep}${builderScale}`, playbackInstrument, playbackOctave)} /></BuilderButton>}
          {!audioEnabled && <BuilderButton onClick={() => setAudioEnabled(true)}><FaVolumeMute size={16} /></BuilderButton>}

          {builderChord}{builderStep}{builderScale}<BuilderButton disabled={!builderChord} selected onClick={(e) => { e.preventDefault(); insertFn(`${builderChord}${builderStep}${builderScale}`) }}>Insert</BuilderButton>
        </BuiltChord>
      </Builder>
    </Menu>
  )
}
