import styled from 'styled-components'

const Base = styled.div`
  display: block;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: .375rem .75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  text-transform: uppercase;
`
export const FormWarning = styled(Base)`
  color: #e65d00;
  border-left: 4px solid #FF6700; 
`

export const FormInfo = styled(Base)`
  color: ${({ theme }) => theme.secondary};
  border-left: 4px solid ${({ theme }) => theme.secondary}; 
`

const FormError = styled(Base)`
  color: #D8000C;
  border-left: 4px solid #FFBABA;
`

export default FormError
