import React, { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { axios } from '../../context/auth'

import FormError from '../../styled/form-error'
import InputGroup from '../../styled/input-group'
import AuthFormContainer from '../../styled/auth-form-container'
import { PrimaryButton } from '../../styled/button'

import { pageView, PAGES } from '../../utils/analytics-tracker'

const RegisterSchema = Yup.object().shape({
  email: Yup.string().min(6).max(150).email().required(),
  password: Yup.string().min(6).max(128).required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
})

const SignupForm = () => {
  const [showCheckEmail, setShowCheckEmail] = useState()

  useEffect(() => {
    pageView(PAGES.REGISTER)
  }, ['-'])

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setErrors } = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (formValues) => submit(formValues)
  })

  const submit = useCallback(async () => {
    window.localStorage.setItem('tabifyRegisterEmail', values.email)

    try {
      await axios.post('/auth/register', {
        email: values.email,
        password: values.password,
        confirmPassword: values.confirmPassword
      })
      setShowCheckEmail(true)
    } catch (err) {
      if (err.response.status === 400) {
        setErrors(err.response.data)
      } else {
        console.log(err)
      }
    }
  })

  if (showCheckEmail) {
    return <div>Check your email to complete registration</div>
  }

  return (
    <>
      <h1>Signup form</h1>
      <AuthFormContainer>
        <InputGroup>
          <label htmlFor='username'>Email: </label>
          <input
            type='text'
            name='email'
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && <FormError>{errors.email}</FormError>}
          <label htmlFor='password'>Password: </label>
          <input
            type='password'
            name='password'
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.password && touched.password && <FormError>{errors.password}</FormError>}
          <label htmlFor='confirmPassword'>Confirm Password: </label>
          <input
            type='password'
            name='confirmPassword'
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.confirmPassword && touched.confirmPassword && <FormError>{errors.confirmPassword}</FormError>}
          <PrimaryButton onClick={handleSubmit}>Sign up</PrimaryButton>
        </InputGroup>
      </AuthFormContainer>
    </>
  )
}

export default SignupForm
