import React from 'react'
import styled from 'styled-components'

import OptionsContainer, { Title } from '../../styled/options-container'

const FlexHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const SongMeta = ({ song }) => {
  return (
    <OptionsContainer>
      <FlexHeader>
        <div><Title>Meta</Title></div>
      </FlexHeader>
      {song.meta1 && <div>1: <span>{song.meta1}</span></div>}
      {song.meta2 && <div>2: <span>{song.meta2}</span></div>}
      {song.meta3 && <div>3: <span>{song.meta3}</span></div>}
      {song.meta4 && <div>4: <span>{song.meta4}</span></div>}
    </OptionsContainer>
  )
}

export default SongMeta
