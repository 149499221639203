import styled from 'styled-components'
import TagsInput from 'react-tagsinput'

export const Suggestion = styled.span`
  display: inline-block;
  padding: .25em .4em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  color: #fff;
  background-color: #6c757d;
  cursor: pointer;
`

export const StyledTagsInput = styled(TagsInput)`
  overflow: hidden;
  
  .react-tagsinput--focused {
    border-color: #a5d24a;
  }

  .react-tagsinput-tag {
    background-color: ${({ theme }) => theme.secondary};
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.primary};
    color: #fff;
    display: inline-block;
    font-family: sans-serif;
    font-weight: 700;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }

  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
  }

  .react-tagsinput-tag a::before {
    content: " ×";
  }

  .react-tagsinput-input {
    width: ${({ noLeftPadding }) => noLeftPadding ? '100%' : '65%;'}
    float: left;
    padding-right: 10px;
  }

  ul {
    list-style-type: none;
    padding-left: 0px;
    li {
      padding-bottom: 4px;
    }
  }
`
