import ReactGA from 'react-ga'

export const pageView = (page) => {
  ReactGA.set({
    page
  })
  ReactGA.pageview(page)
}

export const event = (category, action) => {
  ReactGA.event({
    category,
    action
  })
}

export const CATEGORIES = {
  SONG: 'song',
  SETLIST: 'setlist',
  GROUP: 'group'
}

export const ACTIONS = {
  USER_ADDED: 'user-added',
  USER_REMOVED: 'user-removed',
  USER_REMOVE_CANCEL: 'user-remove-cancel',
  GROUP_ADDED: 'group-added',
  GROUP_REMOVED: 'group-removed',
  GROUP_REMOVE_CANCEL: 'group-remove-cancel',
  CREATED: 'created',
  EDITED: 'edited',
  DELETED: 'deleted',
  QUICK_ADD_SETLIST: 'quick-add-setlist',
  NAVIGATE_SETLIST: 'navigate-setlist',
  SEARCH: 'search',
  SONG_DELETE: 'song-delete',
  SONG_DELETE_CANCEL: 'song-delete-cancel',
  SETLIST_DELETE: 'setlist-delete',
  SETLIST_DELETE_CANCEL: 'setlist-delete-cancel',
  SHOW_DELETE: 'show-delete',
  SHOW_DELETE_CANCEL: 'show-delete-cancel'
}

export const PAGES = {
  SONG_LIST_VIEW: 'song-list-view',
  SONG_VIEW: 'song-view',
  SONG_EDIT: 'song-edit',
  SETLIST_LIST_VIEW: 'setlist-list-view',
  SETLIST_VIEW: 'setlist-view',
  SETLIST_EDIT: 'setlist-edit',
  SETLIST_EXPORT: 'setlist-export',
  SHOW_LIST_VIEW: 'show-list-view',
  SHOW_VIEW: 'show-view',
  SHOW_EDIT: 'show-edit',
  GROUP_LIST_VIEW: 'group-list-view',
  GROUP_VIEW: 'group-view',
  GROUP_EDIT: 'group-edit',
  LOGIN_PAGE: 'login',
  REGISTER: 'register',
  PASSWORD_RESET: 'password-reset',
  PASSWORD_NEW: 'password-new',
  HOME: 'home'
}
