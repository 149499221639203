import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import _map from 'lodash.map'

import Song from './song'
import FailedSearch from './search-failed'
import PageArrow from './page-arrow'

import Loader from '../../styled/loader'
import MetaSelectContainer from '../../styled/meta-select-container'
import SongListContainer, { ListControls } from '../../styled/song-list'
import InputGroup, { Select } from '../../styled/input-group'

import { context as SongListContext } from '../../context/song-list'

import { pageView, PAGES } from '../../utils/analytics-tracker'
import windowStorage from '../../utils/window-storage'

import SearchTags from '../tags/search-tags'
import { ADD_FIELDS } from '../setlists/view'

const ComboSelect = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
`

export default () => {
  const { songs, modifyList, page, tags, filter, count, sortBy, sortOrder, hasMore, loading } = useContext(SongListContext)

  useEffect(() => {
    pageView(PAGES.SONG_LIST_VIEW)
  }, ['-'])

  const [addFieldKey, setAddFieldKey] = useState(windowStorage.getItem('songMetaFieldName') || '')

  const ALPHA_OPTS = [
    <option key='A-Z' value='1'>A-Z</option>,
    <option key='Z-A' value='-1'>Z-A</option>
  ]

  const DATE_OPTS = [
    <option key='new' value='-1'>New</option>,
    <option key='old' value='1'>Old</option>
  ]

  const onAddFieldChange = (e) => {
    windowStorage.setItem('songMetaFieldName', e.target.value)
    setAddFieldKey(e.target.value)
  }

  return (
    <SongListContainer className='App'>
      <div className='songListHeader'>
        <h2>My Songs <Link title='Create a new song' to='/new-song'>+</Link></h2>
        <InputGroup>
          <ListControls>
            <SearchTags noCreate tags={tags} filter={filter} changeFn={modifyList} />
            <div>
              <label>Sort By</label>
              <ComboSelect>
                <Select name='sortBy' defaultValue={sortBy} onChange={(e) => modifyList('sortBy', e.target.value)}>
                  <option value='sortTitle'>Title</option>
                  <option value='sortArtist'>Artist</option>
                  <option value='lastRehearsed'>Last Rehearsal</option>
                  <option value='createdAt'>Created At</option>
                </Select>
                <Select style={{ width: '45%' }} name='sortOrder' defaultValue={sortOrder} onChange={(e) => modifyList('sortOrder', e.target.value)}>
                  {(sortBy === 'sortTitle' || sortBy === 'sortArtist') ? _map(ALPHA_OPTS) : _map(DATE_OPTS)}
                </Select>
              </ComboSelect>
            </div>

            <div>
              <label>Display</label>
              <Select name='count' defaultValue={count} onChange={(e) => { modifyList('count', e.target.value) }}>
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='250'>250</option>
              </Select>
            </div>
            <div style={{ display: 'flex' }}>
              <PageArrow direction='left' enabled={page > 1} onClick={() => modifyList('page', page - 1)} />
              <PageArrow direction='right' enabled={songs && hasMore} onClick={() => modifyList('page', page + 1)} />
            </div>
          </ListControls>
        </InputGroup>
        <MetaSelectContainer>
          <label>Metadata:</label>
          <select id='addFieldSelect' value={addFieldKey} onChange={onAddFieldChange}>
            {_map(ADD_FIELDS, addField => {
              return (
                <option key={`metadata.${addField.key}`} value={addField.key}>{addField.label}</option>
              )
            })}
          </select>
        </MetaSelectContainer>
      </div>
      {(!loading && songs && songs.length > 0) ? _map(songs, song => <Song addFieldKey={addFieldKey} key={song._id} song={song} sortBy={sortBy} />) : !loading && <FailedSearch filter={filter} />}
      {loading && <Loader />}
      <ListControls>
        <PageArrow direction='left' enabled={page > 1} onClick={() => modifyList('page', page - 1)} />
        <Link to='/new-song'>Create Song</Link>
        <PageArrow direction='right' enabled={songs && hasMore} onClick={() => modifyList('page', page + 1)} />
      </ListControls>
    </SongListContainer>
  )
}
