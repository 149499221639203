import React, { useCallback, useEffect, useState } from 'react'

import SongView from '../songs/view'
import SetListView from '../setlists/view'
import { axios } from '../../context/auth'

let interval

const RehearsalView = ({ match }) => {
  const [rehearsal, setRehearsal] = useState({})

  const refresh = useCallback(async () => {
    const response = await axios.get(`/api/rehearsals/${match.params.rehearsalid}`)
    setRehearsal(response.data)
  })

  useEffect(() => {
    refresh()
    interval = setInterval(() => { refresh() }, 3000)
    return () => {
      window.clearInterval(interval)
    }
  }, ['-'])

  const { song: songid, setlist: setlistid } = rehearsal
  return (
    <div>
      {songid && setlistid && <SongView songid={songid} setlistid={setlistid} />}
      {songid && !setlistid && <SongView songid={songid} />}
      {!songid && setlistid && <SetListView setlistid={setlistid} />}
    </div>
  )
}

export default RehearsalView
