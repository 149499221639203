import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 11px;
  margin-bottom: 10px;
  border-top: 1px solid ${({ theme }) => theme.primary};
  border-bottom: 1px solid ${({ theme }) => theme.primary};
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    div {
      margin: 10px;
    }
  } 
`
