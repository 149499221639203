import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { axios } from '../../context/auth'

import { canEdit as canEditUtil } from '../../utils/auth'
import ObjectUsers from '../perms/object-users'
import TitleGroup from '../../styled/title-group'

import { pageView, PAGES, CATEGORIES } from '../../utils/analytics-tracker'

const ViewGroup = () => {
  const navigate = useNavigate()
  const { groupid } = useParams()

  const [group, setGroup] = useState()
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    const loadGroup = async () => {
      const response = await axios.get(`/api/groups/${groupid}`)
      setGroup(response.data)
      setCanEdit(canEditUtil(response.data))
    }
    pageView(PAGES.GROUP_VIEW)
    loadGroup()
  }, [groupid])

  const clickEdit = () => {
    navigate(`/groups/${groupid}/edit`)
  }

  if (!group) {
    return <div>loading</div>
  }
  const roles = ['MEMBER', 'OWNER']
  return (
    <div className='ViewGroup'>
      <TitleGroup
        title={group.title}
        onEdit={canEdit && clickEdit}
      />
      {group && <ObjectUsers objectId={groupid} category={CATEGORIES.GROUP} roles={roles} apiPath='groups' />}
    </div>
  )
}

export default ViewGroup
