import styled from 'styled-components'
import {
  componentMaxWidth
} from '../variables'
import { PrimaryButton } from './button'

export const MarginButton = styled(PrimaryButton)`
  width: 80px;
  height: 30px;
  padding: 0px;
`
export const Title = styled.h4`

`

export default styled.div`
  padding: 1em;
  max-width: ${componentMaxWidth};
  text-align: left;
  border-left: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
`
