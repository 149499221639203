import styled from 'styled-components'

export const DurationContainer = styled.div`
  width: 100%;
  text-align: right;
  padding-bottom: 20px;
`

export const TotalTime = styled.div`
  font-size: 1.8em;
`
