import React, { useCallback, useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { context as AuthContext } from '../../context/auth'

import FormError from '../../styled/form-error'
import InputGroup from '../../styled/input-group'
import AuthFormContainer from '../../styled/auth-form-container'
import { PrimaryButton } from '../../styled/button'
import { pageView, PAGES } from '../../utils/analytics-tracker'

const LoginFormSchema = Yup.object().shape({
  password: Yup.string().min(6).max(128).required(),
  email: Yup.string().min(6).email().required()
})

const showMessage = () => window.localStorage.getItem('refreshToken')

const LoginForm = () => {
  const navigate = useNavigate()
  const { login } = useContext(AuthContext)
  const showLoggedOutMessage = showMessage()

  useEffect(() => {
    pageView(PAGES.LOGIN_PAGE)
  }, ['-'])

  const submit = useCallback(async (values) => {
    const result = await login(values.email, values.password)
    if (result === true) {
      if (window.location.pathname.indexOf('login') > -1) {
        navigate('/songs')
      } else {
        navigate(window.location.pathname)
      }
    } else {
      setFieldError('password', 'Invalid username or password.  Please try again.')
    }
  })

  const { values, errors, touched, setFieldError, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginFormSchema,
    onSubmit: (formValues) => submit(formValues)
  })

  return (
    <div className='LoginForm'>
      <h1>Login to Tabify.Rocks</h1>
      <AuthFormContainer>
        {showLoggedOutMessage && <div>You were logged out due to a long period of inactivity (60 days).</div>}
        <InputGroup>
          <form>
            <label htmlFor='email'>Email: </label>
            <input
              type='email'
              name='email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.email && errors.email && <FormError>{errors.email}</FormError>}
            <label htmlFor='password'>Password: </label>
            <input
              type='password'
              name='password'
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.password && <FormError>{errors.password}</FormError>}
            <PrimaryButton onClick={handleSubmit}>Login</PrimaryButton>
            {' '}
            <Link to='/password-reset'>Forgot Password?</Link><br /><br />
            <Link to='/register'>Don't have an account?  Register here.</Link>
          </form>
        </InputGroup>
      </AuthFormContainer>
    </div>
  )
}

export default LoginForm
