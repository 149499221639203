import styled from 'styled-components'

export default styled.div`
  color: ${({ theme }) => theme.bodyText};
  background-color: ${({ theme }) => theme.background};
  font-family: "Lato", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 1.428571429;
  padding: 2em;
  border: 7px solid ${({ theme }) => theme.primary};
  min-height: 100vh;
  box-shadow: inset 5px 5px 0px 0px ${({ theme }) => theme.secondary};
  margin: 0;
  
  @media(max-width: 600px) {
    border-width: 3px;
    padding: 1.5em;
  }
 
  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.primary};
  }

  @media only screen and (max-width: 500px) {
    h2 {
      font-size: 1.5rem;
    }
  }
  
  a {
    color: ${({ theme }) => theme.secondary};
  }
  
  a:hover {
    color: ${({ theme }) => theme.primary};
  }
  
  h2 a:hover {
    text-decoration: none;
  }
  
`
