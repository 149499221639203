import React, { useState } from 'react'
import styled from 'styled-components'
import YouTube from 'react-youtube'

import getQueryParam from '../../utils/get-query-param'

import OptionsContainer, { MarginButton, Title } from '../../styled/options-container'

const FlexHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

// const mediaClick = link => {
//   // this is incomplete - should only happen for non-youtube links
//   window.open(link, '_blank')
// }

const getYouTube = link => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = link.match(regExp)
  return (match && match[7].length === 11) ? match[7] : false
}

const opts = {
  width: '300',
  height: '200',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
}

const MediaLink = ({ link }) => {
  const [showPlayer, setShowPlayer] = useState(false)
  if (!link) {
    return null
  }

  const youTubeId = getYouTube(link)
  const youtubeStart = getQueryParam('t', link)
  const start = youtubeStart ? { opts: { height: 200, width: 300, playerVars: { start: youtubeStart, autoplay: 1 } } } : {}

  return (
    <OptionsContainer>
      <FlexHeader>
        <div><Title>Media</Title></div>
        <div><MarginButton onClick={() => setShowPlayer(!showPlayer)}>{showPlayer ? 'Hide' : 'View'}</MarginButton></div>
      </FlexHeader>
      {showPlayer && youTubeId && <YouTube opts={opts} videoId={youTubeId} {...start} />}
      {showPlayer && !youTubeId && <a opts={opts} href={link} target='_blank' rel='noreferrer'>{link}</a>}
    </OptionsContainer>
  )
}

export default MediaLink
