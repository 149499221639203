import React, { useCallback, useEffect, useState } from 'react' // useContext
import { useNavigate, useParams } from 'react-router-dom'

import _each from 'lodash.foreach'
import _map from 'lodash.map'
import styled from 'styled-components'

import { canEdit as canEditFn, isOwner as isOwnerFn } from '../../utils/auth'
import { pageView, event, ACTIONS, PAGES, CATEGORIES } from '../../utils/analytics-tracker'
import cachingClient from '../../utils/caching-client'

import ObjectUsers from '../perms/object-users'
import SongSets from '../setlists/song-sets'

import { ConfirmFocal } from '../../styled/confirm-modal'
import { FormWarning } from '../../styled/form-error'
import ControlBox from '../../styled/control-box'
import InputGroup, { SmallInputGroup } from '../../styled/input-group'
import Loader from '../../styled/loader'
import Meta from '../../styled/song-meta'
import TitleGroup from '../../styled/title-group'

import { SONG_KEYS, SIGNATURES } from '../../constants'

import confirm from '../confirmable'
import LastRehearsed from './rehearsed'
import Media from './media'
// import Metronome from '../metronome'
import SetlistNavigation from './setlist-navigation'
import SongContent from './content'
import SongTags from '../tags/song-tags'
import Spotify from './launchSpotify'
import Transpose from './transpose'
import UserNote from './note'
import SongMeta from './song-meta'

import { axios } from '../../context/auth' // context as AuthContext

const Content = styled.div`
  width: 100%;
  @media(min-width: 1024px) {
    display: flex;
  }
`

const Song = styled.div`
  display: inline-block;
  width: 100%;
  @media(min-width: 1024px) {
    width: 65%;
  }
  padding-bottom: 25px;
`

const Features = styled.div`
  display: inline-block;  
  width: 100%;
  @media(min-width: 1024px) {
    border-left: 5px solid ${({ theme }) => theme.borderColor};
    width: 35%;
  }
`

const objectDisplay = obj => obj.title || obj.email

const confirmMessage = (removeObj) => {
  return (
    <div>Are you sure you want delete the song
      <ConfirmFocal>{objectDisplay(removeObj)}</ConfirmFocal>
      ?
    </div>
  )
}

let debounce

// let songSource = axios.CancelToken.source()
// let setlistSource = axios.CancelToken.source()

const ViewSong = () => {
  const navigate = useNavigate()
  const { songid, setlistid } = useParams()

  // const { user } = useContext(AuthContext)
  const [changesLabel, setChangesLabel] = useState('')
  const [loading, setLoading] = useState(true)
  const [savingTransposed, setSavingTransposed] = useState(false)
  const [controlBoxClosed, setControlBoxClosed] = useState(true)
  const [songTheme, setSongTheme] = useState('inline')
  const [changed, setChanged] = useState({})
  const [song, setSong] = useState({})
  const [setlist, setSetlist] = useState()

  const [transposeSteps, setTransposeSteps] = useState(0)
  const canEdit = canEditFn(song)
  const isOwner = isOwnerFn(song)

  const transposeUp = () => {
    setTransposeSteps(transposeSteps === 11 ? 0 : transposeSteps + 1)
  }

  const transposeDown = () => {
    setTransposeSteps(transposeSteps === -11 ? 0 : transposeSteps - 1)
  }

  const refreshSong = useCallback(async (updatedSong) => {
    if (updatedSong) {
      setSong(updatedSong.data)
      setChanged({})
      return
    }
    setLoading(true)
    // if (songSource) {
    //   songSource.cancel('Cancel song fetch, song view unmounted')
    //   songSource = axios.CancelToken.source()
    // }
    try {
      const response = await cachingClient({
        key: 'song',
        id: songid,
        method: 'GET',
        url: `/api/songs/${songid}`,
        cancelToken: '' // songSource.token
      })
      setSong(response.data)
      setChanged({})
      setTransposeSteps(response.data.transposed)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }, [songid])

  // this needs to be in a context
  const refreshSetList = useCallback(async () => {
    // if (setlistSource) {
    //   setlistSource.cancel('Cancel setlist fetch, song view unmounted')
    //   setlistSource = axios.CancelToken.source()
    // }
    if (setlistid) {
      try {
        const response = await cachingClient({
          key: 'setlist',
          id: setlistid,
          method: 'GET',
          url: `/api/setlists/${setlistid}`,
          cancelToken: '' // setlistSource.token
        })
        setSetlist(response.data)
      } catch (err) {
        console.log(err)
      }
    }
  }, [setlistid])

  useEffect(() => {
    refreshSetList()
  }, [setlistid])

  useEffect(() => {
    refreshSong()
  }, [songid])

  useEffect(() => {
    pageView(PAGES.SONG_VIEW)
    return () => {
      // if (setlistSource) {
      //   setlistSource.cancel('Cancel setlist fetch, song view unmounted')
      // }
      // if (songSource) {
      //   songSource.cancel('Cancel song fetch, song view unmounted')
      // }
    }
  }, [])

  const saveChanges = async (changedData) => {
    setChangesLabel('Saving...')
    try {
      const response = await cachingClient({
        key: 'song',
        id: song._id,
        method: 'PUT',
        url: `/api/songs/${song._id}`,
        data: {
          ...song,
          ...changedData
        }
      })

      setSong(response.data)
      setChangesLabel('Saved!')
      setTimeout(() => {
        setChangesLabel('')
      }, 2000)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (JSON.stringify(changed) !== '{}') {
      clearTimeout(debounce)
      debounce = setTimeout(() => {
        saveChanges(changed)
      }, 1000)
    }
  }, [changed])

  const controlsChange = (e) => {
    setChanged({
      ...changed,
      [e.target.name]: e.target.value
    })
    setSong({
      ...song,
      [e.target.name]: e.target.value
    })
    setChangesLabel('Changes...')
  }

  const saveTransposedFn = useCallback(async () => {
    setSavingTransposed(true)
    try {
      const response = await cachingClient({
        key: 'song',
        id: song._id,
        method: 'PUT',
        url: `/api/songs/${song._id}`,
        data: {
          ...song,
          transposed: transposeSteps
        }

      })

      setSong(response.data)
    } catch (err) {
      console.log(err)
    }
    setSavingTransposed(false)
  }, [transposeSteps])

  const clickEdit = (to) => {
    navigate(to)
  }

  const deleteSong = useCallback(() => {
    confirm(confirmMessage(song)).then(
      (result) => {
        axios({
          url: `/api/songs/${song._id}`,
          method: 'DELETE'
        }).then(() => {
          event(CATEGORIES.SONG, ACTIONS.SONG_DELETE)
          navigate('/songs')
        })
      },
      (result) => {
        event(CATEGORIES.SONG, ACTIONS.SONG_DELETE_CANCEL)
      }
    )
  })

  if (!song) {
    return <Loader />
  }

  let nextSong
  let prevSong

  if (setlist) {
    let idx = 0
    _each(setlist.songs, setListSong => {
      if (setListSong._id === songid) {
        prevSong = setlist.songs[idx - 1]
        nextSong = setlist.songs[idx + 1]
      }
      idx++
    })
  }

  const roles = ['VIEWER', 'EDITOR', 'OWNER']

  if (loading) {
    return (
      <div className='SongView'>
        {setlist && <SetlistNavigation setlist={setlist} nextSong={nextSong} prevSong={prevSong} />}
        {loading && <Loader />}
      </div>
    )
  }

  console.log('songid', songid)

  console.log('song', song)
  return (
    <div className='SongView'>
      {setlist && <SetlistNavigation setlist={setlist} nextSong={nextSong} prevSong={prevSong} />}
      {loading && <Loader />}
      {song.cached && <FormWarning>Connection out of tune: Song read from cache</FormWarning>}
      <TitleGroup
        title={song.title}
        subtitle={song.artist}
        onEdit={canEdit && (() => clickEdit(`/songs/${songid}/edit`))}
        onDelete={isOwner && deleteSong}
      />
      {/* <SongInfoHeader>
        <h1>{song.title}
          {canEdit && <EditButton onClick={} />}
          {isOwner && <DeleteButton onClick={deleteSong} />}
        </h1>
        <h2>{song.artist}</h2>
      </SongInfoHeader> */}
      {/* {song && <Metronome user={user} bpm={song.tempo} signature={song.signature} />} */}
      <ControlBox closed={controlBoxClosed}>
        <div className='control-box-header' onClick={() => setControlBoxClosed(!controlBoxClosed)}>Song Controls</div>
        <div className='control-box-body'>
          <InputGroup>
            <label>Transpose{song.transposed !== 0 ? 'd' : ''}</label>
            <Transpose
              song={song}
              value={transposeSteps}
              upFn={transposeUp}
              downFn={transposeDown}
              saveTransposedFn={saveTransposedFn}
              saving={savingTransposed}
            />
          </InputGroup>
          <InputGroup>
            <label>Theme</label>
            <select value={songTheme} onChange={(e) => setSongTheme(e.target.value)}>
              <option value='inline'>Inline</option>
              <option value='above'>Chords Above</option>
              <option value='lyrics-only'>Lyrics Only</option>
              <option value='float-right'>Chords Float Right</option>
            </select>
          </InputGroup>
        </div>
        {canEdit && (
          <div className='control-box-body'>
            <div style={{ clear: 'both' }} />
            <SmallInputGroup>
              <div className='small'>
                <label htmlFor='key'>Key: </label>
                <select name='key' onChange={controlsChange} value={song.key}>
                  {_map(SONG_KEYS, songKey => {
                    return <option key={songKey} value={songKey}>{songKey}</option>
                  })}
                </select>
              </div>
              <div className='small'>
                <label htmlFor='tempo'>Tempo: </label>
                <input
                  type='text'
                  name='tempo'
                  value={song.tempo}
                  onChange={controlsChange}
                />
              </div>
              <div className='small'>
                <label htmlFor='signature'>Signature: </label>
                <select name='signature' onChange={controlsChange} value={song.signature}>
                  {_map(SIGNATURES, sig => {
                    return <option key={sig.value} value={sig.value}>{sig.display}</option>
                  })}
                </select>
              </div>
              <div className='small'>
                <label htmlFor='duration'>Duration: </label>
                <input
                  type='text'
                  name='duration'
                  value={song.duration}
                  onChange={controlsChange}
                />
              </div>
            </SmallInputGroup>
            {changesLabel}
          </div>
        )}

      </ControlBox>

      <Content>
        <Song>
          {song && song.key && <Meta>Key: {song.key}</Meta>}
          {song && song.tempo && <Meta>Tempo: {song.tempo}</Meta>}
          <SongContent
            songTheme={songTheme}
            content={song.content}
            transposeSteps={transposeSteps}
            saveTransposedFn={saveTransposedFn}
          />
        </Song>
        <Features>
          <UserNote song={song} />
          <SongMeta song={song} />
          <Media link={song.media} />
          <Spotify song={song} />
          {canEdit && <LastRehearsed lastRehearsed={song.lastRehearsed} songid={songid} refreshSong={refreshSong} />}
          {canEdit && <SongSets songid={songid} />}
          <SongTags canEdit={canEdit} songid={songid} />
          {song && isOwner && <ObjectUsers objectId={songid} category={CATEGORIES.SONG} roles={roles} apiPath='songs' />}
        </Features>
      </Content>
    </div>
  )
}

export default ViewSong
