import React, { useCallback, useContext, useEffect } from 'react'
import styled from 'styled-components'

import _includes from 'lodash.includes'
import _map from 'lodash.map'

import { useFormik } from 'formik'

import { axios, context as AuthContext } from '../../context/auth'

import { INSTRUMENTS, OCTAVES } from './constants'

import toInstrumentName from '../../utils/title-case-instrument'
import playChord from '../../utils/chord-player'

const Option = styled.div`
   display: flex;
   align-items: top;
   margin-top: 15px;
`
const OptionGroup = styled.div`
  margin-top: 30px;
  border-top: 1px solid black;
  padding-top: 10px;
`

const Description = styled.div`
  display: inline-block;
  font-weight: 200;
  width: 66%;
  font
`

const CheckLabel = styled.div`
  width: 30%;
  display: inline-block;
  label {
    display: inline-block;
    padding-right: 15px;
    white-space: nowrap;
  }
  input {
    vertical-align: middle;
    margin-right: 5px;
  }
  label span {
    vertical-align: middle;
  }
`

const SelectLabel = styled.div`
  width: 30%;
  display: inline-block;
  label {
    display: inline-block;
    padding-right: 15px;
    white-space: nowrap;
  }
  input {
    vertical-align: middle;
    margin-right: 5px;
  }
  label span {
    vertical-align: middle;
    margin-right: 10px;
  }
`

const EditSettings = () => {
  const { user, refreshUser } = useContext(AuthContext)

  const saveSettings = useCallback(async (values) => {
    try {
      await axios({
        method: 'PUT',
        url: '/api/user/settings',
        data: values
      })
      refreshUser()
    } catch (err) {
      console.log(err)
    }
  })

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: user.settings,
    onSubmit: (formValues) => saveSettings(formValues)
  })

  useEffect(() => {
    // refresh user when component unmounts
    return () => {
      refreshUser()
    }
  }, ['-'])

  const change = useCallback((e) => {
    handleChange(e)
    setTimeout(() => {
      handleSubmit()
    }, 100)
  })

  const handleIntrumentChange = useCallback((e) => {
    change(e)
    if (e.target.name === 'playbackInstrument' || e.target.name === 'playbackOctave') {
      setTimeout(() => {
        playChord('G',
          e.target.name === 'playbackInstrument' ? e.target.value : values.playbackInstrument,
          e.target.name === 'playbackOctave' ? e.target.value : values.playbackOctave
        )
      }, 5)
    }
  }, [values])

  return (
    <div className='UserSettings'>
      <h2>User Settings</h2>
      <OptionGroup>
        <h3>Metronome</h3>
        <Option>
          <CheckLabel>
            <label htmlFor='metronomeEnabled'>
              <input
                type='checkbox'
                name='metronomeEnabled'
                checked={values.metronomeEnabled}
                onChange={change}
              /><span>Show Metronome</span>
            </label>
          </CheckLabel>
          <Description>When selected, metronome will display on songs where tempo and time signature are present.</Description>
        </Option>
        <Option>
          <CheckLabel>
            <label htmlFor='metronomeAutoStart'>
              <input
                type='checkbox'
                name='metronomeAutoStart'
                checked={values.metronomeAutoStart}
                onChange={change}
              /><span>Auto Start</span>
            </label>
          </CheckLabel>
          <Description>When selected, song metronome will start on songs automatically when tempo and time signature are present</Description>
        </Option>
      </OptionGroup>
      <OptionGroup>
        <h3>Appearance</h3>
        <Option>
          <SelectLabel>
            <label htmlFor='themePreference'>
              <span>Theme</span>
              <select value={values.themePreference} name='themePreference' onChange={change}>
                <option value='auto'>Auto</option>
                <option value='default'>Light</option>
                <option value='dark'>Dark</option>
              </select>
            </label>
          </SelectLabel>
          <Description>'Auto' will use Light or Dark theme by detecing your device preference.</Description>
        </Option>
      </OptionGroup>
      <OptionGroup>
        <h3>Chord Playback</h3>
        <Option>
          <SelectLabel>
            <label htmlFor='playbackInstrument'>
              <span>Instrument</span>
              <select value={values.playbackInstrument} name='playbackInstrument' onChange={handleIntrumentChange}>
                {_map(INSTRUMENTS, (instrumentName, idx) => (<option key={`select-instrument-option-${idx}`} value={instrumentName} disabled={instrumentName === 'DISABLED'}>{instrumentName === 'DISABLED' ? '------------------------' : toInstrumentName(instrumentName)}</option>))}
              </select>
            </label>
          </SelectLabel>
          <Description>Instrument patch used when playing back chords.</Description>
        </Option>
        <Option>
          <SelectLabel>
            <label htmlFor='playbackOctave'>
              <span>Base Octave</span>
              <select value={values.playbackOctave} name='playbackOctave' onChange={handleIntrumentChange}>
                {_map(OCTAVES, (octave, idx) => (<option key={`select-octave-option-${idx}`} value={octave}>{octave}</option>))}
              </select>
            </label>
          </SelectLabel>
          <Description>The octave where chord playback begins (when slash chord is present, it uses 1 octave lower than this setting.</Description>
        </Option>
      </OptionGroup>
      <OptionGroup>
        <h3>Linked Accounts</h3>
        <Option>
          <SelectLabel>
            <label htmlFor=''>
              <span>Spotify</span><br />
              Status: {_includes(user.linked, 'spotify') ? <span>Linked</span> : <span>Not Linked</span>}
            </label>
          </SelectLabel>
          <Description>Link your spotify account to enable song and playlist playback in Spotify.{!_includes(user.linked, 'spotify') && <a href={process.env.REACT_APP_SPOTIFY_LINK_URL}>Click Here to Link Your Account</a>}</Description>
        </Option>
      </OptionGroup>
    </div>
  )
}

export default EditSettings
