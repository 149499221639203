import _get from 'lodash.get'
import transpose from './transpose-chord'

const pattern = /\{\S+?\}/

export default (song) => {
  const content = _get(song, 'content', '{?}')
  let chord = content.match(pattern)

  chord = chord ? chord[0].replace(/[{}]/g, '') : ''

  if (song.transposed && song.transposed !== 0 && chord !== '') {
    return transpose(chord, song.transposed)
  }

  return chord
}
